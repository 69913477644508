// Node Modules
import React, { Component } from "react";

// Home > Blurb

class Blurb extends Component {
  render() {
    return (
      <>
      
        <h2 className="center-font">App Creation and Number Crunching</h2>
        <p>
          Welcome to Lots of Numbers; we love creating user friendly
          applications and number crunching algorithms.
          <br />
        </p>
        <p>
          We're a couple of IT professionals based in Milton Keynes, United Kingdom. We've worked with
          lots of big brands and organisations to deliver custom built solutions
          that make everyone's lives a little bit easier.
        </p>
        <p>This site allows us to demonstrate the type of work we have completed across the years. Check out what we've been up to...</p>

      </>
    );
  }
}

export default Blurb;
