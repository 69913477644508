// Node Modules
import React from "react";
import { Route, Redirect } from "react-router-dom";

// Custom Modules
import auth from "../../services/authService";

// This returns a Route element so that if users try and manually enter a URL and they have not logged in
// (a user token does not exist) then it will redirect them to the relevant page instead.
// (component and render are specificially extracted from our props passed in)
const ProtectedRoute = ({ component: Component, render, ...rest }) => {
  return (
    <Route
      // Add any other additional properties passed in {...rest}
      // (This includes the path property)
      {...rest}
      // If the user manually tries to go to this URL and the user
      // object does not exist it will redirect them to the login page
      render={(props) => {

        // If a user object is returned to us we can continue to our component
        if (auth.getCurrentUser())
          // (Note: we renamed component with a capital C so React does not complain
          // as it expects elements to start in uppercase)
          // If we have a component then render this, otherwise just return the props
          return Component ? <Component {...props} /> : render(props);

        // No user = redirect to the login url
        // The props.location property has where the user wanted to go (url)
        // so we remember it by setting a custom 'state' prop and set it to the location we originally wanted to go to.
        // We can then use this in out login form to redirect users
        // back to where they wanted to go after logging in.
        return (
          <Redirect
            to={{
              pathname: "/login",
              state: { redirectedFromPath: props.location.pathname },
            }}
          />
        );
      }}
    />
  );
};

export default ProtectedRoute;
