// Node Modules
import React from "react";

// Images
import imgInfo from "../../images/standard/Icon Info.svg";
import imgViewing from "../../images/standard/Icon Viewing.svg";
import imgTech from "../../images/standard/Icon Tech.svg";
import imgDev from "../../images/standard/Icon Dev.svg"

function WorkInfo(props) {

    const {arrInfo} = props

    // Function returns an array of tech used, which will
    // be rendered as an <li> item below
    const getTechList = () => {

        let myResult = [];

        if(arrInfo.length > 0) {
            if(arrInfo[0].tech !== null)
            myResult = arrInfo[0].tech.split(',');
        }
        
        return myResult;
        
    }
    
    const arrTech = getTechList();

    return(
        <>
            <div className="work-info-section">
                <div className="info-title">
                    <img src={imgInfo} alt="About Company" />
                    {arrInfo.length > 0 ? arrInfo[0].companyName : "Company Name"}
                </div>
                <div className="info-text" dangerouslySetInnerHTML={arrInfo.length > 0 ? {__html: arrInfo[0].companyInfo} :{__html: ""}} ></div>
            </div>
            <div className="work-info-section">
                <div className="info-title">
                    <img src={imgViewing} alt="Viewing"/>What we did for them...
                </div>
                <div className="info-text" dangerouslySetInnerHTML={arrInfo.length > 0 ? {__html:arrInfo[0].slideInfo} : {__html: ""}}></div>
            </div>
            <div className="work-info-section">
                <div className="info-title">
                    <img src={imgTech} alt="Tech"/>Technology Used
                </div>
                <div className="info-text">
                
                <ul>
                    {arrTech.length > 0 ? 
                    arrTech.map((o,i) => <li key={i}>{o}</li>) 
                    : null}
                </ul>
                
                </div>
            </div>
            <div className="work-info-section">
                <div className="info-title">
                    <img src={imgDev} alt="Dev"/>Developer
                </div>
                <div className="info-text" dangerouslySetInnerHTML={arrInfo.length > 0 ? {__html:arrInfo[0].developer} : {__html: ""}}></div>
            </div>
        </>
    )

}

export default WorkInfo