import React from "react";

const Mailto = ({ email, subject, body, text }) => {
  return (
    <a
      href={`mailto:${email}?subject=${
        encodeURIComponent(subject) || ""
      }&body=${encodeURIComponent(body) || ""}`}
      tabIndex="-1"
    >
      {text}
    </a>
  );
};

export default Mailto;
