// Custom modules
import http from "./httpService";
import { apiWorkinfoURL } from "./config.json";

export async function getData() {
  // This is an array of slides (screenshots) to display on the work page
  const results = await http.get(apiWorkinfoURL);
  return results.data;
}

export default {
  getData,
};
