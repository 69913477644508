// Node Modules
import React, {useState, useContext, useRef, useEffect} from "react";

// Contexts (Data Sharing)
import  {ContextPublic} from "../store"

// Services (Obtain Data etc)
import svcWorkInfo from "../services/workInfoService";

// Components
import FlexLogos from "./work/flexLogos";
import SlideShow from "./work/slideShow";
import WorkInfo from "./work/workInfo"

function Work() {

  // CONTEXT (public shared data across app)
  // Obtain the public array of logos from this context
  const [statePublic] = useContext(ContextPublic);

  // REFS (Component variables)                    
  const arrSlidesFiltered = useRef([]);
  const arrWorkInfo = useRef([]);

  // STATE
  const [state, setstate] = useState({
    logo_id_selected: (statePublic.arrLogos ? (statePublic.arrLogos.length > 0 ? statePublic.arrLogos[0].logo_id : 0) : 0),
    slide_id_selected: 0
  });

  // GENERIC FUNCTIONS
  // Function sets arrSlidesFiltered to include a list of slides for the logo_id we specify
  const setarrSlidesFiltered = (logo_id) => {
    if(arrWorkInfo.current.length > 0) {
      arrSlidesFiltered.current = arrWorkInfo.current.filter(o => o.logo_id === logo_id);
    }
  }

  // Function gets the first slide_id for the first logo
  // (This is for the initial loading of the page)
  const getDefaultSlide_id = () => {

    let slide_id = 0;

    if(statePublic.arrLogos) {

      // Find our first logo_id available from our array of logos
      if(statePublic.arrLogos.length > 0){
        // Obtain the slides we want to show (updates ref arrSlidesFiltered)
        setarrSlidesFiltered(statePublic.arrLogos[0].logo_id);

        // Return the first slide_id from this filtered list
        if(arrSlidesFiltered.current.length > 0)
          slide_id = arrSlidesFiltered.current[0].slide_id;
      }

    }

    return slide_id;
  }

  //  EVENTS
  // (Run when the public state has changed so we know what slide to default to)
  useEffect(() => {

    async function getAPIData() {

      // arrWorkInfo is an array of slide details and descriptions
      arrWorkInfo.current = await svcWorkInfo.getData();

      setstate({...state, 
        logo_id_selected: (statePublic.arrLogos ? (statePublic.arrLogos.length > 0 ? statePublic.arrLogos[0].logo_id : 0) : 0),
        slide_id_selected: getDefaultSlide_id()
      });
    }
    // Scroll to the top
    window.scrollTo(0, 0);

    getAPIData();

  },[statePublic])


  // Update the image id for our public context when a logo has been clicked
  // (the id is the logo_id from the image array from logosService.js)
  const handleLogo_Click = (e) => {

    // Obtain the slides we want to show (updates ref arrSlidesFiltered)
    setarrSlidesFiltered(Number(e.currentTarget.id));

    // Set our logo id and default slide number to the first one
    setstate({...state, 
                          logo_id_selected: Number(e.currentTarget.id), 
                          slide_id_selected: (arrSlidesFiltered.current.length > 0 ? arrSlidesFiltered.current[0].slide_id : 0)
                   });
  };

  // Previous Slide Button Click event
  const handlePrev_Click = (e) => {

    // Just call this arr (saves constantly typing out arrSlidesFiltered.current)
    const arr = arrSlidesFiltered.current 
    const slide_id_selected = state.slide_id_selected

    // If we are on the first slide go to the last one, otherwise go to previous one.
    if(arr.length > 0){
      setstate({...state,
                      slide_id_selected: slide_id_selected === arr[0].slide_id ? arr[arr.length-1].slide_id : arr[arr.findIndex(o => o.slide_id === slide_id_selected)-1].slide_id
                    })
    }
    
  };

  // Next Slide Button Click event
  const handleNext_Click = (e) => { 

    // Just call this arr (saves constantly typing out arrSlidesFiltered.current)
    const arr = arrSlidesFiltered.current
    const slide_id_selected = state.slide_id_selected

    // If we are on the last slide go to the first one, otherwise go to next one.
    if(arr.length > 0){
      setstate({...state,
                      slide_id_selected: slide_id_selected === arr[arr.length-1].slide_id ? arr[0].slide_id : arr[arr.findIndex(o => o.slide_id === slide_id_selected)+1].slide_id
                      })
    }
  }

  // DOTS
  // Dot Slide Click event
  const handleDotThumbnail_Click = (e) => {
    // Update the image id in the state and re-render
    // (the id is the key from the image array from App.js)
    setstate({...state, 
              slide_id_selected: Number(e.currentTarget.id)})
  };


  return (
      <div className="grid-work">
        <div className="grid-work-logos">
          <FlexLogos 
            arrLogos={statePublic.arrLogos}
            logo_id_selected={state.logo_id_selected}
            handleLogo_Click={handleLogo_Click}/>
        </div>
        <div className="grid-work-screenshots">
          <SlideShow 
            slide_id_selected = {state.slide_id_selected}
            arrSlidesFiltered = {arrSlidesFiltered}
            handlePrev_Click = {handlePrev_Click}
            handleNext_Click = {handleNext_Click}
            handleDotThumbnail_Click = {handleDotThumbnail_Click}/>
        </div>
        <div className="grid-work-info">
          <WorkInfo 
            arrInfo = {arrWorkInfo.current.filter(o => o.slide_id === state.slide_id_selected)}/>
        </div>
      </div>
  )
}

export default Work;
