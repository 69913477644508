// Node Modules
import React, {useEffect} from "react";


function PrivacyPolicy () { 

    // EVENTS
    // When component loads...
    useEffect(() => {
        // Scroll to the top
        window.scrollTo(0, 0);
    }, [])


    return (
        <>
            <div className="middle-content">
                <h2 className="center-font">Privacy Policy</h2>
                <div class="last-updated"><strong>Last Updated: 15 Mar 2021</strong></div>
                <h3>Introduction</h3>
                <p>
                    This privacy policy will explain how our organization uses the personal data we collect from you when you use our website.
                </p>
                <p id="policy-section-1">
                    Topics:
                    <ul>
                        <li><a href="#policy-section-2">What data do we collect?</a></li>
                        <li><a href="#policy-section-3">How will we use your data?</a></li>
                        <li><a href="#policy-section-4">How do we store your data?</a></li>
                        <li><a href="#policy-section-5">What are your data protection rights?</a></li>
                        <li><a href="#policy-section-6">What are cookies?</a></li>
                        <li><a href="#policy-section-7">How do we use cookies?</a></li>
                        <li><a href="#policy-section-8">What types of cookies do we use?</a></li>
                        <li><a href="#policy-section-9">How to manage your cookies</a></li>
                        <li><a href="#policy-section-10">Privacy policies of other websites</a></li>
                        <li><a href="#policy-section-11">Changes to our privacy policy</a></li>
                        <li><a href="#policy-section-12">How to contact us</a></li>
                    </ul>
                </p>
                
                <p id="policy-section-2">
                    <h3>What data do we collect?</h3>
                    Lots of Numbers Ltd collects the following data:
                    <ul>
                        <li>IP address (collected for monitoring site usage statistics)</li>
                    </ul>
                    We do not collect any personal data on this site beyond this which can identify you, unless you contact us directly providing such information. 
                    Personal messages are kept secure at all times and treated as per any email we would normally receive.
                </p>

                <p id="policy-section-3">
                    <h3>How will we use your data?</h3>
                    The data we collect your IP address purely for monitoring site volume statistics and to locate the region of the world you are from. 
                </p>
               
               <p id="policy-section-4">
                   <h3>How do we store your data?</h3>
                   Lots of Numbers Ltd securely stores data using the latest cryptography methods available within our database and web server.
                   <p>
                   Lots of Numbers Ltd will keep your IP address for up to 2 years. Once this time period has expired, we will delete your data from our databases.
                   </p>
               </p>
               
               <p id="policy-section-5">
                    <h3>What are your data protection rights?</h3>
                    Lots of Numbers Ltd would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:

                    <dl>
                        <dd><strong>The right to rectification</strong> - You have the right to request that Lots of Numbers Ltd correct any information you believe is inaccurate. You also have the right to request Lots of Numbers Ltd to complete the information you believe is incomplete.</dd><br/> 

                        <dd><strong>The right to erasure</strong> – You have the right to request that Lots of Numbers Ltd erase your personal data, under certain conditions.</dd><br/>

                        <dd><strong>The right to restrict processing</strong> – You have the right to request that Lots of Numbers Ltd restrict the processing of your personal data, under certain conditions.</dd><br/>

                        <dd><strong>The right to object to processing</strong> – You have the right to object to Lots of Numbers Ltd’s processing of your personal data, under certain conditions.</dd><br/>

                        <dd><strong>The right to data portability</strong> – You have the right to request that Lots of Numbers Ltd transfer the data that we have collected to another organization, or directly to you, under certain conditions.</dd><br/>
                    
                        <dd><strong>The right to access</strong> – You have the right to request Lots of Numbers Ltd for copies of your personal data. We may charge you a small fee for this service.</dd>
                    </dl>

                    If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email: <a href="mailto: info@lotsofnumbers.co.uk">info@lotsofnumbers.co.uk</a>
               </p>

               <p id="policy-section-6">
                    <h3>What are cookies?</h3>
                    Cookies are text files placed on your computer to collect standard Internet log information and visitor behavior information. When you visit our websites, we may collect information from you automatically through cookies or similar technology.

                    For further information, visit <a rel="noopener noreferrer" href="https://allaboutcookies.org" target="_blank">allaboutcookies.org</a>.
               </p>

               <p id="policy-section-7">
                    <h3>How do we use cookies?</h3>
                    Lots of Numbers Ltd uses cookies in a range of ways to improve your experience on our website, including:

                    <ul>
                        <li>Understanding how you use our website</li>
                    </ul>
               </p>

               <p id="policy-section-8">
                    <h3>What types of cookies do we use?</h3>
                    There are a number of different types of cookies, however, our website uses:

                    <ul>
                        <li><strong>Functionality</strong> – Lots of Numbers Ltd uses these cookies so that we recognize you on our website and remember your previously selected preferences. These could include what language you prefer and location you are in.</li>
                    </ul>
               </p>

               <p id="policy-section-9">
                    <h3>How to manage cookies</h3>
                    You can set your browser not to accept cookies, and the above website tells you how to remove cookies from your browser. However, in a few cases, some of our website features may not function as a result.
               </p>

               <p id="policy-section-10">
                    <h3>Privacy policies of other websites</h3>
                    The Lots of Numbers Ltd website contains links to other websites. Our privacy policy applies only to our website, so if you click on a link to another website, you should read their privacy policy.
               </p>
               <p id="policy-section-11">
                    <h3>Changes to our privacy policy</h3>
                    Lots of Numbers Ltd keeps its privacy policy under regular review and places any updates on this web page. You can see when this policy info was last updated at the top of this page.
               </p>
               <p id="policy-section-12">
                    <h3>How to contact us</h3>
                    If you have any questions about Lots of Numbers Ltd’s privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us.

                    <p>
                        Email: <a href="mailto: info@lotsofnumbers.co.uk">info@lotsofnumbers.co.uk</a>
                    </p>
               </p>


            </div>
        </>
    )
}

export default PrivacyPolicy