// Node Modules
import { createRef } from "react";

// This function returns a reference to an element within a component from an array of references.
// You can optionally include addMissingRef so it also adds the reference into the array if it does not exist
const getRef = (refArrFieldRefs, id, addMissingRef = false) => {
  // Create a new reference if it does not exist, otherwise point to existing ref
  if (!refArrFieldRefs.some((i) => i.current && i.current.id === id)) {
    // Create a new reference for each element so we can refer to them
    // (used for focus method in ultimate parent)
    if (addMissingRef) {
      let ref = createRef();

      // Save this ref into our array of references memorised by the parent
      refArrFieldRefs.push(ref);

      return ref;
    }
  } else {
    return refArrFieldRefs.find((i) => i.current.id === id);
  }
};

export default getRef;
