import React from "react";
import { Route } from "react-router-dom";
import Home from "../home";

// This returns a Route element so that it returns to the main page of the application
const MainRoute = ({ ...rest }) => {
  return (
    <Route
      // Add any other additional properties passed in {...rest}
      // (This includes the path property)
      {...rest}
      // If the user manually tries to go to this URL and the user
      // object does not exist it will redirect them to the login page
      render={(props) => {
        // Our main route is our Movies component
        return <Home {...props} />;
      }}
    />
  );
};

export default MainRoute;
