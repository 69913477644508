import React from "react";

const Blurb = () => { 

  return (
    <>
        <h2 className="center-font">Services</h2>
        <div className="center-font services-blurb">What we can do for you...</div>
    </>
  );

};

export default Blurb;