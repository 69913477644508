import React from "react";

const SkillsSubTitle = (props) => { 

  const {
    id,
    subtitle,
    noTopMargin
  } = props;

  return (
    <div id={id} className={"about-skills-card-subtitle " + (noTopMargin ? "no-top-margin" : "")}>{subtitle}</div>
  );

};

export default SkillsSubTitle;