import React from "react";
import { Link } from "react-router-dom";

// Home > Flexcards > Card
// (props are passed from parent)

const card = ({ id, url, img, imgAlt, header, text }) => {
  // return card with image avatar and text below it
  return (
    <div className="card-container" key={id}>
      <Link className="nostyle" to={url}>
        <div className="card-header">
          <img className="card-avatar" src={img} alt={imgAlt} />
          <div className="card-header-top"></div>
          <div className="card-header-middle"></div>
          <div className="card-header-bottom"></div>
        </div>
        <div className="card-content">
          <p className="center-font subheader-font">{header}</p>
          <p className="center-font">{text}</p>
        </div>
      </Link>
    </div>
  );
};

export default card;
