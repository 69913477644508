// Components - custom
import { updateVisitInDB } from "../services/visitsService";
import { toastError } from "./toast/toast";

// This function adds a visit ID when they access the site
// We use a visit timestamp incase multiple browser tabs open creating duplicate visits.
// If they leave the site from one tab then it regards it as leaving the app even if it's open on another.
export async function recordVisit() {
  return new Promise(() => {
    let StorageName = "VID";

    // session is each browser tab (auto clears when tab or browser is closed)
    let session_id = sessionStorage.getItem(StorageName);

    // Starting the app
    if (!session_id) {
      // getTime is the UTC timestamp in milliseconds since the Unix Epoch (1st Jan 1970)
      session_id = new Date().getTime();
      sessionStorage.setItem(StorageName, session_id);
      updateDatabase();
    }

    return;
  });
}

// Update the database with the visit or leaving
async function updateDatabase() {
  const res = await updateVisitInDB();
  if (!res.successful)
    toastError(`Cannot update visit`, res.status, res.response);
}
