// Node Modules
import React, {useState, useEffect, useRef} from "react";
import { Link } from "react-router-dom";

// Custom Modules
import {logout} from "../../services/authService";

const LoginBar = (props) => {

  // This is our user object (which was saved to local storage after successfully logging on; passed in via TopBar as a prop)
  const {user} = props;

  // This is for window resizing event listener so we can 
  // cancel multiple setstate updates being triggered
  let timeoutId = null;
  
  // We have to remember what the user selected so it can be set in a state
  // Since the state is purposely slowed down it might render the wrong answer using 
  // resizeWindowListener. We use this ref so this listener always sets the correct state
  // so that the menu is correctly visible or hidden
  const refHideMenu = useRef(false);

  // STATE
    const [state, setstate] = useState({
      hideMenu: false,
      smallWindow: false
  });


  // GENERIC METHODS
  // Returns true if the window is less than or equal to 576 pixels (mobile phone)
  const usingSmallWindow = () => {
    return (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) <= 576
  }


  //  EVENT HANDLERS
  // This is triggered each time the window changes size
  const resizeWindowListener = () => {

    // prevent execution of previous setTimeout if it exists waiting to be executed
    clearTimeout(timeoutId);

    // This is forcing the state property to only change at most every 150 milliseconds
    // (User may be manually changing the browser window size, so we don't want a constant setstate being triggered.
    // We are also setting the hideMenu state as the user may have altered the size and the admin menu visibility within the last 150ms)
    timeoutId = setTimeout(() => setstate({...state, hideMenu: refHideMenu.current, smallWindow: usingSmallWindow() }), 150);
    
  };

  // Handles when the user clicks on the logout button
  const handle_LogoutClick = async () => {
    //This returns true if successfully logged out.
    if (await logout()) {
      // Go back to the root of this site, which will render everything again (including whether we should show this loginBar)
      window.location = "/";
    }
  }

  // Hide and shows the admin menu at the very top
  const handle_hideMenu =() => {
    refHideMenu.current = !state.hideMenu
    setstate({...state,hideMenu: refHideMenu.current})
  }

  // When component loads...
  useEffect(() => {

    // set resize listener
    window.addEventListener('resize', resizeWindowListener);

    // Determine if we are in small screen mode or not 
    // (this allows the admin menu to be hidden)
    resizeWindowListener();

    // clean up function (when component unloads)
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeWindowListener);
    }
  }, [])  
  
  // If we have an empty user object in state then we do not show this component (user has logged out)
    // (You can only hide the admin menu when you are using a small mobile screen.
    // Changing the class name menuSection to menuSectionHidden will hide the admin menu)
    return ( 
      <div className="loginbar-container">
          <div className="loginbar-collapse" onClick={handle_hideMenu}>{(state.hideMenu ? "Show" : "Hide")} Admin Menu</div>
          <div className={"menuSection" + (state.hideMenu & state.smallWindow ? "Hidden" : "")}>
            <div className="username">{`${user.firstName} ${user.lastName}`}</div>
            <div className="menu-container">
            <ul className="loginbar-menu-items">
              <li>
                <Link to="/admin" tabIndex="-1">Admin</Link>
              </li>
              <li><button onClick={handle_LogoutClick}>Logout</button></li>   
            </ul>
          </div>
          
          </div>
      </div>
    );
}


export default LoginBar;