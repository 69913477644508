// Custom modules
import http from "./httpService";
import { apiSkillspeopleURL } from "./config.json";

export async function getData() {
  // This is an array of skills that each person has experience of (about page)
  const results = await http.get(apiSkillspeopleURL);
  return results.data;
}

export default {
  getData,
};
