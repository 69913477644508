// CSS
// (You can put these into individual components, however I
// just listed them for the whole app since the css uses unique naming conventions.
// Some CSS files such as main, fonts, footer & navbar are used by multiple components
import "./css/fonts.css";
import "./css/main.css";
import "./css/fields.css";
import "./css/loginbar.css";
import "./css/navbar.css";
import "./css/footer.css";
import "./css/work.css";
import "./css/lightbox.css";
import "./css/notFound.css";
import "./css/contact.css";
import "./css/about.css";
import "./css/services.css";
import "./css/termsandconditions.css";
import "./css/login.css";
import "react-toastify/dist/ReactToastify.min.css";

// Node Modules
import React from "react";
import ReactDOM from "react-dom";
import App from "./js/App";
import { BrowserRouter } from "react-router-dom";

// Public variables are held in the store which all the app can access
import Store from "./store";

// process is the current process running
console.log("REACT VERSION: ", React.version);
console.log("REACT_APP_NAME: ", process.env.REACT_APP_NAME);
console.log("REACT_APP_VERSION: ", process.env.REACT_APP_VERSION);
console.log("REACT_APP_API_URL: ", process.env.REACT_APP_API_URL);

ReactDOM.render(
  <Store>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Store>,
  document.getElementById("root")
);
