// Node Modules
import React, {useState, useEffect, useRef} from "react";

// Components
import Fields from "../common/fields";

// Custom Modules
import {reset} from "../../services/authService"
import {toastError ,toastInfo} from "../../js/toast/toast"
import getRef from "../../js/getRefs";

// Images
import imgResetPwd from "../../images/standard/Reset Password.svg"

function ResetPassword() {

    // STATE
    const [state, setstate] = useState({
        blnEnableButton: false,
        showComplete: false,
        arrFields: [
            {
                id: "txtPassword",
                fieldType: "Input",
                type: "password",
                tabIndex: "1",
                blnFullWidth: true,
                blnShowTick: false,
                txtLabel: "Current Password:",
                txtPlaceholder: "Enter Your Current Password",
                minLength: "8",
                maxLength: "255",
                blnRequired: true
            },
            {
                id: "txtPasswordNew",
                fieldType: "Input",
                type: "password",
                tabIndex: "2",
                blnFullWidth: true,
                blnShowTick: false,
                txtLabel: "New Password:",
                txtPlaceholder: "Enter Your New Password",
                minLength: "8",
                maxLength: "255",
                blnRequired: true
            },
            {
                id: "txtPasswordConfirm",
                fieldType: "Input",
                type: "password",
                tabIndex: "3",
                blnFullWidth: true,
                blnShowTick: false,
                txtLabel: "Confirm New Password:",
                txtPlaceholder: "Re-enter Your New Password",
                minLength: "8",
                maxLength: "255",
                blnRequired: true
            },
        ]
    });

    // A list of refs for each rendered element for dynamically created fields
    // (fields created are based on the arrFields array shown above)
    // When the item is rendered a reference to the field is created via <Fields/>
    const refArrFieldRefs = useRef([]);

    const createBodyObject = () => {

        const result = {};

        // Set the properties of our movie object to the field info values
        result.password = getRef(refArrFieldRefs.current, "txtPassword").current.value;
        result.newpassword = getRef(refArrFieldRefs.current, "txtPasswordNew").current.value;
    
        return result;
    }

    // EVENTS
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Make sure our confirmed password matches
        const refConfirmed = getRef(refArrFieldRefs.current, "txtPasswordConfirm")

        if (getRef(refArrFieldRefs.current, "txtPasswordNew").current.value !== refConfirmed.current.value) {
            toastError("The new password you entered mismatches the confirmed password. Try again...")
            refConfirmed.current.value = "";
            refConfirmed.current.focus();
            return;
        }

        // This is the body of our request sent to the back end
        const body = createBodyObject();

        // await for the server response from back end
        const result = await reset(body);

        if(result.updated){
            toastInfo(`Your password has been successfully updated`);
            setstate({...state, showComplete: true});

        }else {
            toastError(`Cannot log on`,result.status, result.response);
        }
    }

    // Load: Simulates componentDidMount() method.
    // This will only run once - when the component has mounted
    useEffect(() => {

        // Set the focus to the first input field
        const ref = getRef(refArrFieldRefs.current, "txtPassword")
        ref.current.focus();
        
    },[])

    const handleField_Change = (e) => {
    
        // This is our target field being changed
        const target = e.currentTarget

        // make a copy of our array of objects held by the state
        let arrCopy = [...state.arrFields];

        // find our object from array that corresponds to the target field that just changed
        // (variable is a pointer to the object in the copied array - not a copy of the object)
        let o = arrCopy.find(o => o.id === target.id)

        // if required length in field has been met then we should show the tick image
        let showTick = (target.value.length >= Number(o.minLength) 
                        && target.value.length !== 0)

        // If we have altered the showTick flag then update the state
        if (showTick !== o.blnShowTick) {
            // updated object with new flag
            o.blnShowTick = showTick

            // Do we enable the submit button (every required field must be completed correctly)
            const arrRequired = arrCopy.filter(o => o.blnRequired === true);
            const blnEnableButton = (arrRequired.filter(o => o.blnShowTick === false).length === 0)

            // Update the state so it can re-render
            setstate({...state, 
                blnEnableButton,
                arrFields: arrCopy
              });
        }
    }

    return (
        <>
        <div className="admin-container flex-center">
            {
            state.showComplete ? 
            <div className="admin-form-border">
                <img className="center-horizontally medium" src={imgResetPwd} alt="password updated" />
                <h3 className="center-font">Password successfully updated</h3>
            </div>
             :
            <div className="admin-form-border">
                <form 
                className="form-container"
                onSubmit={handleSubmit}>
                    <h3 className="center-font">Reset Your Password</h3>
                    <Fields
                        refArrFieldRefs = {refArrFieldRefs}
                        arrFields = {state.arrFields}
                        onChange = {handleField_Change}
                    />
                    <button 
                        className={`standard ${!state.blnEnableButton ? "disabled" : ""}`}  
                        disabled={!state.blnEnableButton}
                        type="submit"
                        tabIndex="4">
                        Reset Password
                    </button>
                </form>  
            </div>
            }
            
        </div>
        </>
    );

}

export default ResetPassword;