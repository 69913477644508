import React, { Component } from "react";
import { Link } from "react-router-dom";

// Components
import Mailto from "./mailTo";

class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <div className="footer-left">
          <h3 className="footer-font">Lets chat</h3>
          <p className="standard-font">
            e:{" "}
            <Mailto
              email="info@lotsofnumbers.co.uk"
              subject="Lots of Numbers - Enquiry"
              body=""
              text="info@lotsofnumbers.co.uk"
            />
          </p>
        </div>
        <div className="footer-middle">
          <h3 className="footer-font">Lots of Numbers</h3>
          <p className="standard-font">
            We are a couple of IT professionals based in Milton Keynes, United Kingdom.
          </p>
          <p className="standard-font">
            We love making user friendly applications and number crunching analytics.
          </p>
        </div>
        <div className="footer-right">
          <h3 className="footer-font">Important Stuff</h3>
          <p className="standard-font">
          <Link to="services" tabIndex="-1">Services</Link>
            <br />
            <Link to="about" tabIndex="-1">About Us</Link>
            <br />
            <Link to="contact" tabIndex="-1">Contact Us</Link>
            {/* <br /> */}
            {/* <Link to="terms" tabIndex="-1">Terms and Conditions</Link> */}
            {/* <br /> */}
            {/* <Link to="privacy" tabIndex="-1">Privacy Policy</Link> */}
          </p>
        </div>
        <div className="footer-bottom">
          Copyright &#169; {new Date().getFullYear()}. Lots of Numbers
        </div>
      </div>
    );
  }
}

export default Footer;
