// Node Modules
import React, {useState, useEffect, useRef} from "react";

// Services (Obtain Data etc)
import svcServices from "../services/servicesService"


// Components
import Blurb from "./services/blurb";
import ServiceCard from "./services/serviceCard";


function Services () { 

    // REFS (Component variables) 
    const arrServices = useRef([]);

    // STATE
    const [state, setstate] = useState({
    });

    // EVENTS
    // When component loads...
    useEffect(() => {
        async function getAPIData() {
        // arrSkillsPeople is an array listing all the skills each person has
        arrServices.current = await svcServices.getData();

        // Update the state so the page renders
        setstate({...state});
        }
        // Scroll to the top
        window.scrollTo(0, 0);

        getAPIData();
      }, [])

    return (
        <>
            <div className="middle-content">
                <Blurb/>
                {arrServices.current 
                    ? arrServices.current.map(o => (
                        <ServiceCard 
                        key={o.services_id}
                        id={o.services_id}
                        imgURLHeader={o.imgURLHeader}
                        imgAltHeader={o.imgAltHeader}
                        headerText={o.headerText}
                        imgURLCard={o.imgURLCard}
                        imgAltCard={o.imgAltCard}
                        cardText={o.cardText}
                        flippedCard={o.flippedCard}
                    />
                    ))
                : null}
            </div>
        </>
    )
}

export default Services