//  Custom Modules
import http from "./httpService";
import { apiMessageURL } from "./config.json";

// RESET PASSWORD
export async function sendMessage(body) {
  let response = { messageSent: false };

  try {
    // This get request removes the secure cookie that enables logging in (completed via back end)
    const res = await http.post(apiMessageURL, body);

    if (res.status === 200) {
      response = { messageSent: true };
    } else {
      response = {
        updated: false,
        status: res.status,
        response: res.data,
      };
    }
  } catch (err) {
    response = {
      updated: false,
      status: err.response.status,
      error_message: err.message,
      response: err.response.data,
    };
  }

  return response;
}
