// Custom modules
import http from "./httpService";
import {
  apiVisitsURL,
  apiVisitorInfoURL,
  apiVisitorChartURL,
} from "./config.json";
import { createResponseObject } from "../js/httpResponses";

export async function updateVisitInDB() {
  try {
    const res = await http.post(apiVisitsURL);

    return createResponseObject(res);
  } catch (err) {
    return createResponseObject(undefined, err);
  }
}

export async function getVisitorInfo(startDate, endDate) {
  try {
    const res = await http.get(apiVisitorInfoURL, {
      params: { startDate: startDate, endDate: endDate },
    });

    return createResponseObject(res, undefined, true);
  } catch (err) {
    return createResponseObject(undefined, err);
  }
}

export async function getVisitorChartData(startDate, endDate, groupBy) {
  try {
    const res = await http.get(apiVisitorChartURL, {
      params: { startDate: startDate, endDate: endDate, groupBy: groupBy },
    });

    return createResponseObject(res, undefined, true);
  } catch (err) {
    return createResponseObject(undefined, err);
  }
}

export default {
  updateVisitInDB,
};
