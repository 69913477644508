import React, {useState, useEffect, useRef} from "react";
import { Link } from "react-router-dom";

const NavBar = () => {

  // This is for window resizing event listener so we can 
  // cancel multiple setstate updates being triggered
  let timeoutId = null;

  // REFS
  const refShowMenu = useRef(true)

  // GENERIC FUNCTIONS
  const showMenuDefault = () => {
    let res = !usingSmallWindow()

    refShowMenu.current = res
    return res
  }

  const usingSmallWindow = () => {
    return window.innerWidth <= 768;
  }

  // STATE
  const [state, setstate] = useState({
    blnShowMenu: showMenuDefault(),
    usingSmallWindow: usingSmallWindow()
    });

  //  EVENT HANDLERS
  const handle_ShowHideMenu = () => {

    refShowMenu.current = !state.blnShowMenu
    setstate({...state,blnShowMenu: !state.blnShowMenu})
  }

  // This is triggered each time the window changes size
  const resizeWindowListener = () => {

    // prevent execution of previous setTimeout if it exists waiting to be executed
    clearTimeout(timeoutId);

    // This is forcing the state property to only change at most every 150 milliseconds
    // (User may be manually changing the browser window size, so we don't want a constant setstate being triggered.
    // We are also setting the blnShowMenu state as the user may have altered the size and the menu visibility within the last 150ms)
    timeoutId = setTimeout(() => setstate({...state, blnShowMenu: refShowMenu.current, usingSmallWindow: usingSmallWindow() }), 150);
    
  };

  // When component loads...
  useEffect(() => {

    // set resize listener
    window.addEventListener('resize', resizeWindowListener);

    // Determine if we are in small screen mode or not 
    // (this allows the admin menu to be hidden)
    resizeWindowListener();

    // clean up function (when component unloads)
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeWindowListener);
    }
  }, [])  


  return (
    <div className="nav-container">

      {/* Hamburger Menu */
      state.usingSmallWindow ?
          <div className="hamburger-menu">
            <div className="hamburger-menu-content">
                <input type="checkbox" onChange={handle_ShowHideMenu} checked={state.blnShowMenu}/>
                <div className="hamburger-menu-slices">
                  <div className="slice" />
                  <div className="slice" />
                  <div className="slice" />
                </div>
            </div>
        </div> : 
        null
      }


      {/* Logo and Title */}
      <div className="logo-container">
        <div className="below">
          <img id="logo-black" src={require("../../images/standard/LogoBlack.svg")} alt="Black Logo" tabIndex="-1"/>
        </div>

        <div className="above">
          <Link to="/" tabIndex="-1">
            <img id="logo-color" src={require("../../images/standard/LogoColour.svg")} alt="Logo" tabIndex="-1"/>
          </Link>
        </div>

        <div className="text">
          <Link to="/" tabIndex="-1">Lots of Numbers</Link>
        </div>
      </div>

      {/* Menu */}
      <div className={`menu-container ${!state.blnShowMenu && state.usingSmallWindow ? "hide" : ""}`}>
        <ul className="menu-items">
          <li><Link to="/services" tabIndex="-1">Services</Link></li>
          <li><Link to="/about" tabIndex="-1">About Us</Link></li>
          <li><Link to="/work" tabIndex="-1">Our Work</Link></li>
          <li>
            <Link className="menu-highlight" to="/contact" tabIndex="-1">
              Get In Touch
            </Link>
          </li>
        </ul>
      </div>

    </div>
      
  );
  
}

export default NavBar;
