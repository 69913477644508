import React from "react";
import { Link } from "react-router-dom";

// Images
import imgLightBulb from "../../images/standard/Logo Lightbulb.svg";

// Home > Stripe
// (props are passed from parent)

const stripe = ({ url, imgAlt, text, buttonText }) => {
  // return the coloured stripe with image, text and button
  return (
    <div className="stripe subheader-font">
      <img className="stripe-image" src={imgLightBulb} alt={imgAlt} />
      <div className="stripe-blurb">{text}</div>
      <Link className="button-highlight-link" to={url}>
        {buttonText}
      </Link>
    </div>
  );
};

export default stripe;
