// Node Modules
import React from "react";

// Components
import Logo from "./logo";

function Flexlogos(props) {

  const {logo_id_selected,arrLogos, handleLogo_Click} = props

  return (
    <>
      <p className="top-blurb">
        Select a company to read about some projects we completed for them...
      </p>
      <div className="flex-logos">
        {arrLogos
          ? arrLogos.map((logo) => (
              <Logo
                key={logo.logo_id}
                id={logo.logo_id.toString()}
                img={logo.img}
                imgAlt={logo.imgAlt}
                selected={logo.logo_id === logo_id_selected}
                onClick={handleLogo_Click}
              />
            ))
          : null}
      </div>
    </>
  );

}

export default Flexlogos;
