// Returns an object that can be handled by the caller. Object properties vary depending on what was returned by API call
// res:           the response provided by the API
// err:           the error caught when trying to call the API (if applicable)
// returnArray:   returns a response property with the first item found in the response data array (there should only be one).
//                If set to true then it returns arrData property (an array of results) instead because the response has more than one value / object
export function createResponseObject(
  res,
  err = undefined,
  returnArray = false
) {
  let r = { successful: false };

  if (err) {
    r = {
      successful: false,
      status: err.response ? err.response.status : null,
      error_message: err.message,
      response: err.response ? err.response.data : null,
    };
  } else if (res && String(res.status).substr(0, 1) === "2") {
    r = {
      successful: true,
      status: res.status,
    };

    returnArray
      ? (r.arrData = res.data)
      : (r.response = Array.isArray(res.data) ? res.data[0] : res.data);
  } else {
    r = {
      successful: false,
      status: res.status,
      response: res.statusText,
    };
  }

  return r;
}
