import React from "react";

import SkillsItem from "./skillsItem"
import SkillsSubTitle from "./skillsSubTitle"

const SkillsCard = (props) => { 

    const {
        category,
        arrItems,
        skill_id_selected,
        arrSkill_ids_enabled,
        handle_onClick
    } = props;

    return (
        <>
            <div className="about-skills-card">
                <div className="about-skills-card-content">
                    <div className="about-skills-card-title">{category}</div>
                    {arrItems 
                        ? arrItems.map(o => (
                            o.subtitle ? 
                            <SkillsSubTitle 
                            key={o.skill_id}
                            id={o.skill_id.toString()}
                            subtitle={o.subtitle}
                            noTopMargin={o.noTopMargin}
                            />

                            :<SkillsItem 
                                key={o.skill_id}
                                id={o.skill_id.toString()}
                                selectedId={skill_id_selected}
                                imgURL={o.imgURL}
                                imgAlt={o.imgAlt}
                                skillName={o.skillName}
                                selected={o.skill_id === skill_id_selected}
                                indent={o.indent}
                                enabled={arrSkill_ids_enabled.indexOf(o.skill_id) !== -1}
                                selected_info={o.selected_info}
                                handle_onClick={handle_onClick}
                            />
                    ))
                    : null}
                </div>
            </div>
        </>
    );

};

export default SkillsCard;