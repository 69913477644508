import React, {forwardRef} from "react";

// We have to use forwardRef because we want to set focus to the first
// input field once everything has been rendered. This occurs in the ultimate parent element
const CheckBox = forwardRef((props,ref) => {
  
  const { 
      id
    , noAnimation
    , tabIndex
    , blnFullWidth
    , txtLabel
    , onChange
    , checked
    , disabled} = props;

  return (
    <div className={`field-container 
                    ${blnFullWidth ? "fullwidth" : "halfwidth"} 
                    ${noAnimation ? "no-animation" : ""}`}>      
        <label className={`checkbox-container ${disabled ?  "disabled" : ""}`}>{txtLabel}
            <input 
                id={id} 
                ref={ref} 
                type="checkbox" 
                tabIndex={tabIndex}
                defaultChecked={checked} 
                disabled={disabled}
                onChange={onChange}
            />
            <span className={`checkbox-square ${disabled ?  "disabled" : ""}`} />
        </label>
    </div>
  );
  
}
);

export default CheckBox;