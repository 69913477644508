import React from "react";

const ModalSlide = ({ id, slideNumber, totalSlides, img, imgAlt }) => {

    return (

        <div className="modal-slide">
            <div className="modal-slide-number">{slideNumber} / {totalSlides}</div>
            <img
            id={id}
            src={process.env.PUBLIC_URL + `${img}`}
            alt={imgAlt}
            />
        </div>

    );
}

export default ModalSlide;