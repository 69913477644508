// Node Modules
import React from "react";

// Components
import ModalSlide from "./modalSlide"

const LightBox = (props) => {

  // Props passed in from SlideShow
  // ==================================
  // props.handleLightboxClose        (calls this function from the parent to close the lightbox component)
  // props.arrSlidesFiltered          (an array of slides that have been filtered for a particular logo)
  // props.slide_id                   (our current slide id that should be displayed to the user)
  // props.handlePrev_Click           (alters the image to the previous one)
  // props.handleNext_Click           (alters the image to the next one)
  // props.handleDotThumbnail_Click   (alters the image to the thumbnail)
  
  const {
        handleLightboxClose,
        arrSlidesFiltered,
        slide_id,
        handlePrev_Click,
        handleNext_Click,
        handleDotThumbnail_Click} = props;

  // Close the Modal
  const closeModal = () => {
    handleLightboxClose()
  }

  // This is our selected image the user just clicked on (it's an array so get the first item from results)
  const imgSelected = arrSlidesFiltered.current.filter(o => o.slide_id === slide_id)[0];

  // This is our slide number (based on array index)
  const slideNumber = arrSlidesFiltered.current.findIndex(o => o.slide_id === slide_id)+1

  return (
    <div id="myModal" className="modal">
      <div className="modal-content">
        <div className="top-bar">
          <div id="btnClose" onClick={closeModal}>
            &times;
          </div>
        </div>
        
        {/* <!-- Modal Images (Only show one slide at a time based on slide_id we clicked on) --> */}

        <ModalSlide 
          key={imgSelected.slide_id}
          id={imgSelected.slide_id}
          slideNumber={slideNumber}
          totalSlides={arrSlidesFiltered.current.length}
          img={imgSelected.imgURL}
          imgAlt={imgSelected.imgAlt}
        />


        {/* <!-- Next/previous controls --> */}
        {
        // If we have more than one slide show the previous button
        arrSlidesFiltered.current.length > 1 ? <div className="prev" onClick={handlePrev_Click}>&#10094;</div>: null}

        {
        // If we have more than one slide show the next button
        arrSlidesFiltered.current.length > 1 ? <div className="next" onClick={handleNext_Click}>&#10095;</div>: null}

        {/* <!-- Caption text --> */}
        <div className="caption-container">
          <p id="modal-caption">{imgSelected.imgAlt}</p>
        </div>

        {/* <!-- Thumbnail image controls --> */}
        <div className="scrolling-thumbnails">
          { arrSlidesFiltered.current.length > 1 ?
            arrSlidesFiltered.current.map((slide, i) => (
            <img
              key={slide.slide_id}
              id={slide.slide_id}
              className={"modal-thumbnail" + (slide.slide_id === slide_id ? " active" : "")}
              src={process.env.PUBLIC_URL + `${slide.imgURL}`}
              onClick={handleDotThumbnail_Click}
              alt={slide.imgAlt}
            />
          )): null}
        </div>

      </div>
    </div>
  );
}

export default LightBox;
