// Node Modules
import React, {useState, useRef, useEffect} from "react";

// Components
import UserNew from "./userNew";
import UserEdit from "./userEdit";

// Services
import {getUsernames} from "../../services/userService";

// Custom Modules
import {toastError} from "../../js/toast/toast"

function UserAccounts(props) {

    // PROPS
    const {currentUser} = props;
           
    // STATE
    const [state, setstate] = useState({
        selectedOption: "showNew",
        selectedUser_id: undefined,
        showCompleted: false,
        deletedAccount: false
    });

    // REFS
    // An array of usernames we can select ({user_id, username})
    const refArrUsernames = useRef([]);

    // GENERIC FUNCTIONS
    // Updates the list of usernames select drop down list
    const updateUsernames = async() => {
        // Obtain a list of usernames
        const res = await getUsernames();

        if(!res.successful){
            return toastError(`Cannot obtain usernames`,res.status,res.response)                
        }

        // Update our reference to an array of usernames
        refArrUsernames.current = res.arrData
    }

    const updateAsCompleted = async (deletedUser = false) => {

        // Get the latest list of usernames so they can be displayed in the drop down menu
        await updateUsernames()

        setstate({...state,
            selectedUser_id: undefined,
            showCompleted: true,
            deletedAccount: deletedUser
            })
    }

    // Returns the appropriate HTML depending on what we selected 
    // (create new account or edit existing account)
    const showHeaderOptions = () => {

        return (
        <>
        <div className="field-container options no-animation">
            <label className="radio-container">Create A New User Account
                <input type="radio" 
                    checked={state.selectedOption === "showNew"}
                    name="radioGroup" 
                    value="showNew"
                    onChange={handleRadioGroup_Change}/>
                <span className="radio-circle" />
            </label>
            <label className="radio-container">Edit Existing User Account
                <input type="radio" 
                    checked={state.selectedOption === "showEdit"}
                    name="radioGroup" 
                    value="showEdit" 
                    onChange={handleRadioGroup_Change}/>
                <span className="radio-circle" />
            </label>
        </div>
        {state.selectedOption === "showEdit" ?
            <div className="field-container options no-animation">
                <label htmlFor="cmbSelectUsername" className="float-left">Username:</label>
                <span >
                    <select
                        id="SelectUsername"
                        tabIndex={-1}
                        className="field-select" 
                        defaultValue={"DEFAULT"}
                        onChange={handleSelectUsername_Change}>
                            <option key={0} value="DEFAULT" disabled hidden>Select Username...</option>
                            {refArrUsernames.current ? refArrUsernames.current.map((o,i) => <option key={i} value={`${o.username}`}>{`${o.username}`}</option>) : null}
                    </select>
                </span>               
            </div>
        : null}
        </>
        )
    }

    // EVENTS
    const handleRadioGroup_Change = (e) => {
    
        // This is our target field being changed
        const target = e.currentTarget

        // rerender the new fields
        setstate({...state,selectedOption: target.value, selectedUser_id: undefined, showCompleted: false })
    }


    const handleSelectUsername_Change = async (e) => {

        const target = e.currentTarget;

        // Render the form using new user id details
        const user_id = refArrUsernames.current.find(o => o.username === target.value).user_id;
        setstate({...state,selectedUser_id: user_id, showCompleted: false})

    }

    // Load: Simulates componentDidMount() method.
    // This will only run once - when the component has mounted
    useEffect(() => {
        updateUsernames();
    },[])


    return (
        <>
            {showHeaderOptions()}
            <div className="admin-form-border">
                {state.selectedOption === "showNew" ? 
                    <UserNew
                        currentUser={currentUser}
                    /> : 
                    <UserEdit
                        currentUser={currentUser}
                        selectedUser_id={state.selectedUser_id}
                        showCompleted={state.showCompleted}
                        deletedAccount={state.deletedAccount}
                        updateAsCompleted={updateAsCompleted}
                    />}
            </div>
        </>
    );

}

export default UserAccounts;