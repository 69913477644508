// Custom modules
import http from "./httpService";

export async function getData() {
  // Gets data from the default api path from NodeJS.
  // (if NodeJS express server is running then it will return an object with a message and timestamp)
  // This is shared via ContextPublic (see store.jsx | apiConnection: true / false)
  try {
    const results = await http.get(process.env.REACT_APP_API_URL);
    return typeof results.data !== "undefined";
  } catch (err) {
    return false;
  }
}

export default {
  getData,
};
