// Node Modules
import React, {useState, useRef, useEffect} from "react";

// Components
import Fields from "../common/fields";
import SectionHeader from "../common/sectionHeader";

// Services
import {getUserDetails, updateUser, deleteUser} from "../../services/userService";

// Custom Modules
import {toastError} from "../../js/toast/toast"
import getRef from "../../js/getRefs";

// Images
import imgUser from "../../images/standard/user.svg";
import imgDev from "../../images/standard/Icon Dev.svg";
import imgInfo from "../../images/standard/Icon Info.svg";

function UserEdit(props) {

    // PROPS
    const {
        currentUser,
        selectedUser_id,
        showCompleted, 
        deletedAccount,
        updateAsCompleted} = props
  
    // STATE
    const [state, setstate] = useState({
        blnEnableButton: false,
        blnMyAccount: false,
        arrFields: [
            {
                id: "txtUsername",
                fieldType: "Input",
                type: "text",
                tabIndex: "1",
                blnFullWidth: true,
                blnShowTick: false,
                txtLabel: "Username:",
                txtPlaceholder: "Enter Username",
                minLength: 3,
                maxLength: 100,
                blnRequired: true,
            },
            {
                id: "chkActive",
                fieldType: "Checkbox",
                tabIndex: "2",
                blnFullWidth: false,
                blnShowTick: false,
                txtLabel: "Account Active",
                checked: false,
                disabled: false,
            },
            {
                id: "chkAdmin",
                fieldType: "Checkbox",
                tabIndex: "3",
                blnFullWidth: false,
                blnShowTick: false,
                txtLabel: "Admin (Can create / modify users)",
                checked: false,
                disabled: false,
            }
        ]
    });

    // REFS
    // A list of refs for each rendered element for dynamically created fields
    // (fields created are based on the arrFields array shown above)
    // When the item is rendered a reference to the field is created via <Fields/>
    const refArrFieldRefs = useRef([]);


    // VARIABLES
    // This is a list of fields to show that are read only
    const arrInfoFields = [
        {
            id: "info_user_id",
            fieldType: "InfoField",
            noAnimation: true,
            blnFullWidth: false,
            txtLabel: "User ID:",
        },
        {
            id: "info_last_logged_on",
            fieldType: "InfoField",
            noAnimation: true,
            blnFullWidth: false,
            txtLabel: "Last Logged On:",
        },
        {
            id: "info_last_modified",
            fieldType: "InfoField",
            noAnimation: true,
            blnFullWidth: true,
            txtLabel: "Account Last Modified:",
        },
        {
            id: "info_created",
            fieldType: "InfoField",
            noAnimation: true,
            blnFullWidth: true,
            txtLabel: "Account Created:",
        },
        {
            id: "info_locked",
            fieldType: "InfoField",
            noAnimation: true,
            blnFullWidth: true,
            txtLabel: "Account Locked:",
        },
    ]

    // GENERIC FUNCTIONS
    // You cannot update your own account for active and admin settings
    const disableFieldsForMyAccount = () => {

        // Only do this if they are not already disabled
        let fieldsDisabled = state.arrFields.filter(o => (o.id === "chkActive" && o.disabled) || 
                                                        (o.id === "chkAdmin" && o.disabled)).length > 0

        let updateState;
        let myAccount = (currentUser.user_id === selectedUser_id);

        // We are trying to edit our own account and fields are not disabled OR
        // we are trying to edit someone else's account and fields are disabled...
        if((myAccount && !fieldsDisabled) ||
            (!myAccount && fieldsDisabled)){
            updateState = true
        }

        if(updateState) {
            // make a copy of our array of objects held by the state
            let arrCopy = [...state.arrFields];

            // find our object from array that corresponds to a particular field id
            // (variable is a pointer to the object in the copied array - not a copy of the object)
            let o = arrCopy.find(o => o.id === "chkActive");
            o.disabled = !fieldsDisabled;

            o = arrCopy.find(o => o.id === "chkAdmin");
            o.disabled = !fieldsDisabled;

            setstate({...state, blnMyAccount: myAccount, arrFields: arrCopy})
        }

    }

    // Creates the body object for our HTTP request
    const createBodyObject = (bodyType = 'saveUser') => {
    
        const body = {};

        switch (bodyType){
            case 'saveUser':
            
                body.username = getRef(refArrFieldRefs.current, "txtUsername").current.value;
                body.is_active = getRef(refArrFieldRefs.current, "chkActive").current.checked;
                body.is_admin = getRef(refArrFieldRefs.current, "chkAdmin").current.checked;
                body.modified_by = `${currentUser.firstName ? currentUser.firstName : ""} ${currentUser.lastName ? currentUser.lastName : ""}`;
                break;

            case 'deleteUser':
                body.deleted_by = `${currentUser.firstName ? currentUser.firstName : ""} ${currentUser.lastName ? currentUser.lastName : ""}`;
                break;

            default:

        }

        return body;
    }

    // Formats the epoch date returned by the database into a date
    const formatDate = (myDate, missingDateResponse = "No Date") => {
        if(myDate) return new Date(myDate);
        return missingDateResponse;
    }

    // This is the HTML we want to display
    const getHTML = () => {
        // User Details Saved
        if(showCompleted) {
            return (
                <div>
                    <img className="center-horizontally medium" src={imgUser} alt="user" />
                    <h3 className="center-font">User Account Successfully {deletedAccount ? `Deleted` : `Saved`}</h3>
                </div>
            )
        }      
        //  Username has not been selected yet
        if(!selectedUser_id) {
            return <h3 className="center-font">Select a username from the menu above...</h3>
        }
        
        // Show user details and form
        return (
            <form 
            className="form-container"
            onSubmit={handleSubmit}>
                <SectionHeader 
                    imgURL={imgDev}
                    imgAlt="Info"
                    text={`Account Info`}
                />
                <Fields
                    refArrFieldRefs = {refArrFieldRefs}
                    arrFields = {state.arrFields}
                    onChange = {handleField_Change}
                />
                <button 
                    className={`standard ${!state.blnEnableButton ? "disabled" : ""}`}  
                    disabled={!state.blnEnableButton}
                    type="submit"
                    tabIndex="99">
                    Save
                </button>
                <button 
                    className={`standard ${state.blnMyAccount ? "disabled" : ""}`} 
                    disabled={state.blnMyAccount}  
                    type="button"
                    tabIndex="99"
                    onClick={handleDelete}>
                    Delete Account
                </button>
                <br></br>
                <SectionHeader 
                    imgURL={imgInfo}
                    imgAlt="Info"
                    text={`Additional Information (Read Only)`}
                />
                <Fields
                    refArrFieldRefs = {refArrFieldRefs}
                    arrFields = {arrInfoFields}
                />

            </form>   
        )
    }

    // EVENTS
    // Each time selectedUser_id changes we set the values of the fields
    useEffect(() => {

        async function updateFields() {
            // Obtain the user details and render out the HTML (selectedUser_id was passed in as a prop)
            if(selectedUser_id){

                let updateIndicators;

                const res = await getUserDetails(selectedUser_id);

                if(!res || !res.successful){
                    return toastError(`Cannot obtain user details`,res.status,res.response);
                }
           
                // update our user object so details can be rendered 
                let user = res.response;
    
                if(user){
                    // Update the states for the input fields so they rerender correctly (field complete indicators)
                    updateIndicators = true

                    // Update values for the fields
                    getRef(refArrFieldRefs.current,"txtUsername").current.value = user.username;
                    getRef(refArrFieldRefs.current,"chkActive").current.checked= user.is_active;
                    getRef(refArrFieldRefs.current,"chkAdmin").current.checked= user.is_admin;

                    // Info only fields
                    getRef(refArrFieldRefs.current,"info_user_id").current.innerHTML = user.user_id;
                    getRef(refArrFieldRefs.current,"info_last_logged_on").current.innerHTML = formatDate(user.last_logged_on,`User has not logged on yet`);
                    getRef(refArrFieldRefs.current,"info_last_modified").current.innerHTML = `<strong>Timestamp:</strong>    ${formatDate(user.last_modified)}<br>
                                                                                            <strong>Modified By:</strong>  ${user.last_modified_by ? user.last_modified_by : ``}<br>
                                                                                            <strong>Modified By IP:</strong>  ${user.last_modified_by_ip ? user.last_modified_by_ip : ``}`;
                    getRef(refArrFieldRefs.current,"info_created").current.innerHTML = `<strong>Timestamp:</strong>    ${formatDate(user.created)}<br>
                                                                                        <strong>Created By IP:</strong>  ${user.created_by_ip ? user.created_by_ip : ``}`;
                    getRef(refArrFieldRefs.current,"info_locked").current.innerHTML = `<strong>Account Locked Until:</strong>    ${formatDate(user.locked_until, `Account is not locked`)}<br>
                                                                                    <strong>Failed Log on Attempts:</strong>  ${user.log_on_attempts}<br>
                                                                                    <strong>Last Log on Attempt via IP:</strong>  ${user.log_on_attempt_ip ? user.log_on_attempt_ip : 'User has not logged on yet'}`;
                }
 
                // Set the focus to the first input field
                const ref = getRef(refArrFieldRefs.current, state.arrFields.find(o => o.tabIndex === "1").id);
                if(ref) ref.current.focus();

                // If we updated values for the fields we have to trigger the field indicators to update 
                // (the small tick images that appear for required fields)
                if(updateIndicators) updateFieldIndicators("txtUsername");
                
            }
            
        }

        // If a selectedUser_id prop is passed in then show this users details on the form       
        updateFields();        
    },[selectedUser_id]);


    // Function updates the field indicators based on an array of fields passed in
    // (it also determines if the form button should be enabled as well)
    const updateFieldIndicators = (...args) => {

        // Obtain a list of references for the field IDs passed in
        const arrFields = []
        args.forEach(fieldID => {
            arrFields.push(getRef(refArrFieldRefs.current,fieldID).current)
        })

        // make a copy of our array of objects held by the state
        let arrCopy = [...state.arrFields];

        arrFields.forEach(f => {
            // find our object from array that corresponds to the target field that just changed
            // (variable is a pointer to the object in the copied array - not a copy of the object)
            let o = arrCopy.find(o => o.id === f.id)

            // if required length in field has been met then we should show the tick image
            if(o.fieldType === "Input") {
                let showTick = (f.value.length >= Number(o.minLength) 
                && f.value.length !== 0)

                // If we have altered the showTick flag then update the state
                if (showTick !== o.blnShowTick) {
                // updated object with new flag
                o.blnShowTick = showTick;
                
                }
            }
        })

        // Do we enable the submit button (every required field must be completed correctly)
        const arrRequired = arrCopy.filter(o => o.blnRequired === true);
        const blnEnableButton = (arrRequired.filter(o => o.blnShowTick === false).length === 0)

        // Update the state so it can re-render
        setstate({...state, 
            blnEnableButton,
            arrFields: arrCopy
        });
        
    }

    // Every time a field is altered this will run...
    const handleField_Change = (e) => {
    
        // Update the field indicator for the field that is being changed
        // (also figures out if save button should be enabled)
        updateFieldIndicators(e.currentTarget.id)
       
    }

    // When the user saves the user details...
    const handleSubmit = async (e) => {
        e.preventDefault();

        // This is the body of our request sent to the back end
        const body = createBodyObject();

        // await for the server response from back end
        const res= await updateUser(selectedUser_id,body);

        if(res.successful){

            // Clear our references to the fields as they will no longer be displayed
            // (This allows us to recreate our refs again in the next render via <Fields> component, 
            // otherwise the field refs will remain in memory but they all become null as they are no longer available in the DOM)
            refArrFieldRefs.current = []; 

            // Run this method which is from the parent component
            // (this updates the list of names and rerenders this component to show completed HTML)
            updateAsCompleted()


        }else {
            toastError(`Cannot update user account`,res.status, res.response);
        }
    }

    const handleDelete = async (e) => {
        e.preventDefault();      

        // This is the body of our request sent to the back end
        const body = createBodyObject('deleteUser');

        // await for the server response from back end
        const res= await deleteUser(selectedUser_id, body);

        if(res.successful){

            // Clear our references to the fields as they will no longer be displayed
            // (This allows us to recreate our refs again in the next render via <Fields> component, 
            // otherwise the field refs will remain in memory but they all become null as they are no longer available in the DOM)
            refArrFieldRefs.current = []; 

            // Run this method which is from the parent component
            // (this updates the list of names and rerenders this component to show completed HTML)
            // true parameter just makes sure that is says we completed deleting the account rather than saving it.
            updateAsCompleted(true)

        }else {
            toastError(`Cannot delete user account`,res.status, res.response);
        }
    }

    disableFieldsForMyAccount()

    return (
        <>{getHTML()}</>
    );

}

export default UserEdit;