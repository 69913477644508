import React from "react";

const SkillsItem = (props) => { 

  const {
    id,
    imgURL,
    imgAlt,
    skillName,
    selected,
    indent,
    enabled,
    selected_info,
    handle_onClick
  } = props;

  return (
    <>

        <div id={id}  
            className={"about-skills-card-item" + 
                        (selected ? " selected" : "" ) + 
                        (indent ? " indent" : "") +
                        (!enabled ? " disabled" : "")} 
            onClick={handle_onClick} 
                                    >
            <img src={process.env.PUBLIC_URL + `${imgURL}`} alt={imgAlt}/>{skillName}
        </div>
        
        {selected ?
            <div className={"about-skills-card-item-info"} dangerouslySetInnerHTML={{__html: selected_info}}></div>
        : null}
        
    </>
  );

};

export default SkillsItem;