import React, { forwardRef } from "react";

// We have to use forwardRef because we want to set focus to the first
// input field once everything has been rendered. This occurs in the ultimate parent element
const TextArea = forwardRef((props, ref) => { 

  const {
      id
    , noAnimation
    , tabIndex
    , blnFullWidth
    , blnShowTick
    , txtLabel
    , txtPlaceholder
    , minLength
    , maxLength
    , blnRequired
    , imgFieldIndicator
    , onChange} = props;

  return (
    <div className={`field-container nobackground 
                    ${blnFullWidth ? "fullwidth" : "halfwidth"}
                    ${noAnimation ? "no-animation" : ""}`}>
        <span className="field-label">{txtLabel}</span>
        {
          blnShowTick ? 
          <img src={imgFieldIndicator} className="field-indicator-image" alt="Completed Tick" /> :
          <span className="field-indicator">{blnRequired ? "Required" : "Optional"} </span>
        }
        
        <textarea 
          id={id} 
          ref={ref}
          className="field-textarea" 
          placeholder={txtPlaceholder}
          minLength={minLength}
          maxLength={maxLength} 
          tabIndex={tabIndex} 
          required={blnRequired ? "required" : ""} 
          onChange={onChange}>
        </textarea>
    </div>
  );

}
);

export default TextArea;