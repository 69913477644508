import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../../services/authService";

// This returns a Route element so that if users try and manually enter the login URL after they have logged in
// (a user token does exist) it will just redirect them to the relevant 'logged in' page instead.
// (component and render are specificially extracted from our props passed in)
const LoginRoute = ({ component: Component, render, ...rest }) => {
  return (
    <Route
      // Add any other additional properties passed in {...rest}
      // (This includes the path property)
      {...rest}
      // If the user manually tries to go to this URL and the user
      // object does not exist it will redirect them to the login page
      render={(props) => {

        // If we cannot get a user object saved (which is saved to localstorage after successfully logging on)
        // then redirect them to our login component as we intended
        if (!auth.getCurrentUser())
          // (Note: we renamed component with a capital C so React does not complain
          // as it expects elements to start in uppercase)
          // If we have a component then render this, otherwise just return the props
          return Component ? <Component {...props} /> : render(props);

        // No user = redirect to the 'logged in' url (i.e. the admin page)
        return (
          <Redirect to={{pathname: "/admin"}}/>
        );
      }}
    />
  );
};

export default LoginRoute;
