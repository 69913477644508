// Node Modules
import React from "react";

// Custom components
import LoginBar from "./loginBar";
import NavBar from "./navbar";
import auth from "../../services/authService";

const TopBar = () => {

  // If a user object is returned then we are logged in (show the LoginBar)
  const user = auth.getCurrentUser();

  return (
    <div className="topbar-container">
        {user ? <LoginBar user={user}/> : null}
        <NavBar/>
    </div>
  );
}

export default TopBar;