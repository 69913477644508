// Custom modules
import http from "./httpService";
import { apiUsernamesURL, apiUsersURL, apiMeURL } from "./config.json";
import { createResponseObject } from "../js/httpResponses";

// USERNAMES
// Returns a list of usernames in the system
export async function getUsernames() {
  try {
    const res = await http.get(apiUsernamesURL);

    return createResponseObject(res, undefined, true);
  } catch (err) {
    return createResponseObject(undefined, err);
  }
}

// USER DETAILS
// Returns the user details for a user_id
export async function getUserDetails(user_id) {
  try {
    // The axios params name is misleading. In NodeJS you're actually setting req.query not req.params
    // In NodeJS req.params is url/param. In NodeJS req.query is url?id=1
    // https://stackoverflow.com/questions/53739951/axios-post-request-parameters-to-backend-are-undefined
    const res = await http.get(apiUsersURL, { params: { id: user_id } });

    return createResponseObject(res);
  } catch (err) {
    return createResponseObject(undefined, err);
  }
}

// MY DETAILS
// Returns the current user details from the database for whoever is running this
export async function getMyUserDetails() {
  try {
    const res = await http.get(apiMeURL);

    return createResponseObject(res);
  } catch (err) {
    return createResponseObject(undefined, err);
  }
}

// CREATE USER
// Updates the user details
export async function createUser(body) {
  try {
    const res = await http.post(apiUsersURL, body);

    return createResponseObject(res);
  } catch (err) {
    return createResponseObject(undefined, err);
  }
}

// UPDATE USER
// Updates the user details
export async function updateUser(user_id, body) {
  try {
    const res = await http.put(apiUsersURL, body, { params: { id: user_id } });

    return createResponseObject(res);
  } catch (err) {
    return createResponseObject(undefined, err);
  }
}

// DELETE USER
// Updates the user details
export async function deleteUser(user_id, body) {
  // Bad names...
  // axios data is the property to set the request body
  // axios params is the propery to set the request query
  return await http
    .delete(apiUsersURL, { data: body, params: { id: user_id } })
    .then((res) => {
      return createResponseObject(res);
    })
    .catch((err) => {
      return createResponseObject(undefined, err);
    });
}

export default {
  getUsernames,
};
