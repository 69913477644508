import React, {useState} from "react";

// Components
import SectionHeader from "../common/sectionHeader"

const ServiceCard = (props) => { 

    const {
        imgURLHeader,
        imgAltHeader,
        headerText,

        imgURLCard,
        imgAltCard,
        cardText,
        flippedCard
    } = props;

    // STATE
    const [state, setstate] = useState({
        initialise: true,
        highlight: false
    });

    // This is our css style property that gets added to 
    // the section you hover over so it scales up on hover
    const css = {
        transform:'scale(1.025)',
        WebkitTransform: 'scale(1.025)',
        transition: 'transform .2s'
    }

    return (
        <>
            <div
                className={"services-card "  + (state.initialise ? "animation-pop-in": "")}
                style={state.highlight ? css : null}
                onMouseEnter={() => setstate({...state, initialise:false, highlight: true})}
                onMouseLeave={() => setstate({...state, initialise:false, highlight: false})}
                >
                <SectionHeader
                    imgURL={imgURLHeader}
                    imgAlt={imgAltHeader}
                    text={headerText}
                    imgInvertColours={true}
                    highlight={state.highlight}
                />
                <div className={"services-container " + (flippedCard ? "flipped " : "")}>
                    <img className="services" src={process.env.PUBLIC_URL + `${imgURLCard}`} alt={imgAltCard}/> 
                    <div className="section-text" dangerouslySetInnerHTML={{__html: cardText}}></div>
                </div>
            </div>
        </>
    );

};

export default ServiceCard;