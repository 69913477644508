import React from "react";

// Components
import Clock from "./clock";

// Images 
import imgEmail from "../../images/standard/Icon Email.svg";
import imgLinkedIn from "../../images/standard/Icon LinkedIn.svg";
import imgTech from "../../images/standard/Icon Tech.svg";

const Profile = (props) => { 

    // PROPS
    const {
        person_id,
        personName,
        email,
        linkedIn,
        profileText,
        imgURL,
        handle_techFilterClick,
        techButtonSelected,
        showClock} = props;

    return (
        <>
        
            <div className="about-person-container">
                <div className="about-person-img" style={{backgroundImage: `url(${imgURL}`}}>
                    {showClock ? <Clock/> : null}
                </div>
                <div className="about-person-info">
                    <div className="about-person-title">
                        <div className="name">{personName}</div>
                        <a className="no-hover-decoration" rel="noopener noreferrer" href={`mailto:${email}`}><div className="email"><img src={imgEmail} alt="email" />Email</div></a>
                        <a className="no-hover-decoration" rel="noopener noreferrer" href={linkedIn} target="_blank"><div className="linkedIn"><img src={imgLinkedIn} alt="LinkedIn" />LinkedIn</div></a>
                        <div id={person_id} className={"skills " + (techButtonSelected ? "selected": "")} onClick={handle_techFilterClick}><img src={imgTech} alt="Tech Skills" />Technologies</div>
                    </div>
                    <div className="section-text" dangerouslySetInnerHTML={{__html:`${profileText}` }}></div>
                </div> 
            </div>

        </>
    );

};

export default Profile;