// Node Modules
import React, {useState, useEffect} from "react";

// Components
import Table from "../common/table";
import TableSmall from "../common/tableSmall";
import VisitModal from "./visitModal";
import LineChart from "../common/chartLine";

// Services
import {getVisitorInfo, getVisitorChartData} from "../../services/visitsService";

// Custom Modules
import {toastError} from "../../js/toast/toast"


function VisitorInfo() {

    // VARIABLES
    const minYear = 2021;

    // These are the columns we want to show in our table
    // The columnName is the columns we want to display in the table of data. Alias is the header for the table column
    const arrShowColumns = [
        {columnName:"session_timestamp", alias: "Timestamp"},
        {columnName:"country_name", alias: "Country"},
        {columnName:"region_name", alias: "Region"},
        {columnName:"city", alias: "City"}];

    // This is the list of columns (fields) we want to show in our pop up modal when user clicks on row
    const arrModalFields = [
        {columnName:"session_timestamp", alias: "Timestamp", order: 1},
        {columnName:"ip_address", alias: "IP Address", order: 2},
        {columnName:"ip_type", alias: "IP Type", order: 3},
        {columnName:"continent_name", alias: "Continent", order: 4},
        {columnName:"country_name", alias: "Country", order: 5},
        {columnName:"region_name", alias: "Region", order: 6},
        {columnName:"city", alias: "City", order: 7},
        {columnName:"zip", alias: "ZIP", order: 8},
        {columnName:"location_capital", alias: "Country Capital", order: 9},
        {columnName:"is_eu", alias: "Country In EU", order: 10},
        {columnName:"calling_code", alias: "Country Calling Code", order: 11}
    ]

    // GENERIC FUNCTIONS
    // Returns the last month for the year (if it's the current year or unspecified it will return the latest month of this year)
    const getLatestMonth = (year = new Date().getFullYear()) => {
        if(year < new Date().getFullYear()) return 12

        return new Date().getMonth()+1
    }

    const usingSmallWindow = () => {
        return window.innerWidth <= 425;
    }

    // STATE
    const [state, setstate] = useState({
        showYear: String(new Date().getFullYear()),
        showMonth: getLatestMonth(),
        arrData: [],
        arrChartData: [],
        showDetails: false,
        showDetailsRID: 0,
        tabSelected: "tabChart",
        smallWindow: usingSmallWindow(),
        chartXAxisUnit: "day"
    });

    const returnMonths = () => {
        const arrMonths = []

        const maxMonth = state.showYear === String(new Date().getFullYear()) ? new Date().getMonth() : 11;

        // Jan = 0 ... Dec = 11
        for(let i=0; i <= maxMonth; i++){
            arrMonths.push(new Date(minYear,i,1).toLocaleString('default', { month: 'long' }))
        }

        return arrMonths
    }

    const returnYears = () => {
        const arrYears = []

        for(let i=new Date().getFullYear(); i >= minYear; i--) {
            arrYears.push(i)
        }

        return arrYears
    }

    // Returns what we should display for 'All Months' option in select drop down menu
    const getDefaultAllMonths = () => {

        let maxMonth;

        // Show (Jan - Dec) unless you only have one option then say (Jan - LatestMonth)
        if (state.showYear === "All" && new Date().getFullYear() !== minYear) {
            maxMonth = 11;
        }
        else {
            maxMonth = state.showYear === String(new Date().getFullYear()) ? new Date().getMonth() : 11;
        }
        

        const name = new Date(2020,maxMonth,1).toLocaleString('default', { month: 'short' });

        if(name === 'Jan') {
            return `All Months (Jan Only)})`
        }
        return `All Months (Jan - ${name})`
    }

    // Returns what we should display for 'All Years' option in select drop down menu
    const getDefaultAllYears = () => {

        const thisYear = new Date().getFullYear();

        if(minYear === thisYear) {
            return `All Years (${thisYear})`
        }
        return `All Years (${minYear} - ${thisYear})`
    }

    // Returns a YYYY-MM-DD formatted string for the last day of the month for year and month you specify
    const getLastDayofMonthString = (year, month) => {

        // month is zero indexed (Jan = 0, Feb = 1 ... Dec = 11)
        let d = new Date(year,month-1,1)

        // Add a month on to this date
        d = new Date(d.setMonth(d.getMonth()+1))

        // Subtract a day - this give us our last day of the month
        d =  d.setDate(d.getDate()-1);

        const myDate = new Date(d)

        // Return formatted string (YYYY-MM-DD)
        return `${myDate.getFullYear()}-${(String(myDate.getMonth()+1)).padStart(2,'0')}-${myDate.getDate()}`

    }

    // Returns a specific RID (row ID) so it can be displayed in modal pop up
    const getDetails = () => {
        return state.arrData.filter(o => o.RID === state.showDetailsRID)[0]
    }

    // Returns the appropriate HTML depending on what we selected 
    // (create new account or edit existing account)
    const showHeaderOptions = () => {

        return (
        <>
        <div className={`field-container options no-animation`}>   
            <div className="inline">
                <label htmlFor="SelectYear">Year:</label>
                <span>
                    <select
                        id="SelectYear"
                        tabIndex={1}
                        className="field-select" 
                        value={state.showYear}
                        onChange={handle_SelectYearChange}>
                            <option key={0} value="All">{getDefaultAllYears()}</option>
                            {returnYears().map((o,i) => <option key={i+1} value={o}>{`${o}`}</option>)}
                    </select>
                </span> 
            </div>
                       
            <div className="inline">
                <label htmlFor="SelectMonth">Month:</label>
                <span>                
                    <select
                        id="SelectMonth"
                        tabIndex={2}
                        className="field-select" 
                        value={state.showMonth}
                        disabled={state.showYear === "All"}
                        onChange={handle_SelectMonthChange}>
                            <option key={0} value="All" >{getDefaultAllMonths()}</option>
                            {returnMonths().map((o,i) => <option key={i+1} value={i+1}>{`${o}`}</option>)}
                    </select>
                </span>
            </div>
            
        </div>           
        </>
        )
    }

    // Requests data via HTTP axios call and updates the state
    const updateData = async () => {
 
        let arrData = [];
        let arrChartData = [];
        const groupBy = state.showMonth === "All" ? "month" : "day"

        // Query date range
        const startDate = `${state.showYear === "All" ? minYear : state.showYear }-${state.showMonth === "All" ? "01" : String(state.showMonth).padStart(2,'0')}-01`;
        const endDate = getLastDayofMonthString(state.showYear === "All" ? new Date().getFullYear() : state.showYear, state.showMonth === "All" ? String(getLatestMonth(state.showYear)).padStart(2,'0') : state.showMonth);

        // Raw data
        let res = await getVisitorInfo(startDate, endDate);

        if(!res || !res.successful){
            // 404 = no data (not really an error - HTML will show this rather than a toast pop up)
            if(res.status !== 404)
                toastError(`No Results (Raw Data)`,res.status,res.response);
        }
        else {
            arrData = res.arrData
        }

        // Chart Data
        res = await getVisitorChartData(startDate, endDate,groupBy)

        if(!res || !res.successful){
            // 404 = no data (not really an error - HTML will show this rather than a toast pop up)
            if(res.status !== 404)
                toastError(`No Results (Chart)`,res.status,res.response);
        }
        else {
            arrChartData = padDates(startDate, endDate, groupBy, res.arrData)
        }
        
        setstate({...state,arrData, arrChartData, chartXAxisUnit: groupBy})
    
    }

    // Function pads out our array of dates and values so chart can display zero values
    const padDates = (startDate, endDate, groupBy, arrData) => {

        // Make sure we have a valid array of data objects (time, value)
        if(arrData && Array.isArray(arrData) && arrData.length > 0) {
            let myDate = startDate;
            let i = -1;
            let myZero;

            do {
                // this is our array index
                i++
                // if we don't have this date in our array, add it with zero value
                if(!arrData.some(o => o.date === myDate)) {
                    myZero = {date: myDate, value: 0}
                    arrData.splice(i,0,myZero)
                }

                // next date we should check...
                // convert our existing date string into a date
                myDate = new Date(myDate);

                // add to this date the appropriate unit (day or month)
                if(groupBy === 'day'){
                    // add a day to the date
                    myDate.setDate(myDate.getDate()+1)
                    // Turn this back into a string so we can find it in our array
                    myDate = `${myDate.getFullYear()}-${String(myDate.getMonth()+1).padStart(2,'0')}-${String(myDate.getDate()).padStart(2,'0')}`
                }else {
                    // add a month to the date
                    myDate = new Date(myDate.setMonth(myDate.getMonth()+1))
                    // Turn this back into a string so we can find it in our array (always use 01 for day)
                    myDate = `${myDate.getFullYear()}-${String(myDate.getMonth()+1).padStart(2,'0')}-01`
                }

            } while (Date.parse(myDate) <= Date.parse(endDate))

        }

        return arrData
    }

    // EVENTS
    const handle_SelectMonthChange = (e) => {
        const target = e.currentTarget;

        // Selected all months
        if(target.value === "All"){

            setstate({...state,
                        showMonth: "All"})
        }
        // selected a year
        else {
            setstate({...state,
                showMonth: target.value
                }) 
        }
    }

    const handle_SelectYearChange = (e) => {
        
        const target = e.currentTarget;

        // Selected all years
        if(target.value === "All" ){
            setstate({...state,
                        showYear: "All",
                        showMonth: "All"})
        }
        // selected a year
        else {
            setstate({...state,
                showYear: target.value,
                showMonth: "All"}) 
        }
    }

    const handle_closeVisitModal = () => {
        setstate({...state, showDetails: false, showDetailsRID: 0})
    }

    const handle_showVisitModal = (e) => {

        const target = e.currentTarget

        setstate({...state, showDetails: true, showDetailsRID: parseInt(target.id)})
    }

    const handle_tabClick = (e) => {
        const target = e.currentTarget;

        setstate({...state,tabSelected: target.id})

    }

    // Each time we make a select change we update the table
    useEffect(() => {
        updateData(); 
    },[state.showYear, state.showMonth]);


    return (
        <>
        {state.showDetails ? 
            <VisitModal 
            handle_Close = {handle_closeVisitModal}
            arrFields = {arrModalFields}
            data = {getDetails()}
            /> : 
            null}
        <div className="admin-container">
            <h3 className="center-font">Visitor Info</h3>
            {showHeaderOptions()}
            <div className="admin-form-border">

            <div className="tab-container">
                <button id="tabChart" className={state.tabSelected === "tabChart" ? "active" : ""} onClick={handle_tabClick}>Chart</button>
                <button id="tabData" className={state.tabSelected === "tabData" ? "active" : ""} onClick={handle_tabClick}>Data</button>
            </div>

            {
                // Chart
                state.tabSelected === "tabChart" ?

                state.arrChartData.length === 0 ?
                <h3 className="center-font">No Data</h3> : 
                <LineChart
                    id="lineChart"
                    arrData={state.arrChartData}
                    xAxisUnit = {state.chartXAxisUnit}
                    smallWindow = {state.smallWindow}
                />
                
                :

                // Data
                state.arrData.length === 0 ?
                    <h3 className="center-font">No Data</h3> : 
                    <>
                    <div className={state.smallWindow ? "center-font" : ""}><strong>Results: {state.arrData.length}</strong></div>
                    {state.smallWindow ? 
                        <TableSmall 
                        arrData={state.arrData}
                        arrShowColumns={arrShowColumns}
                        handle_rowClick={handle_showVisitModal}
                        /> : 
                        <Table
                        id="tableVisitors"
                        arrData={state.arrData}
                        arrShowColumns={arrShowColumns}
                        handle_rowClick={handle_showVisitModal}
                        />
                    }
                    </>
            }
            
            </div>
        </div>
        </>
    );

}

export default VisitorInfo;