
// Node Modules

// useRef (variable.current = value) : holds variable values for life of component
// useState ([stateName, methodToUpdate]) : holds values for life of component but triggers re-rendering when method is called
import React, {useState} from "react";

// Components
import Lightbox from "../common/lightbox";
import Slide from "./slide";

function SlideShow (props) {
 
  const {slide_id_selected, 
         arrSlidesFiltered, 
         handlePrev_Click, 
         handleNext_Click, 
         handleDotThumbnail_Click} = props;

  // States: trigger rendering when changed
  const [showLightBox, setshowLightbox] = useState(false);

  // =======================================================================
  

  // MODAL POP UP FUNCTIONS
  const handleSlide_Click = (e) => {

    // Only open the modal lightbox if we have a larger screen as
    // the purpose of this is to see a bigger image
    if (window.innerWidth > 660){
        setshowLightbox(true)
    }
  }

  // This function gets passed to the child lightbox element to trigger
  const handleLightboxClose = () => setshowLightbox(false)
  

  return (
    <>
      {/* Display the modal lightbox if the user has clicked on the image.
      We pass in the same prev / next image handlers because we want the original image and dots 
      to change as well behind the scenes as they click through them */}
      {showLightBox ? <Lightbox 
                      handleLightboxClose={handleLightboxClose} 
                      arrSlidesFiltered={arrSlidesFiltered}
                      slide_id={slide_id_selected}
                      handlePrev_Click={handlePrev_Click}
                      handleNext_Click={handleNext_Click}
                      handleDotThumbnail_Click={handleDotThumbnail_Click}
                      /> : null }

      <div className="slideshow-container">
      {
      // Display the slides by toggling the display parameter
      arrSlidesFiltered.current.length > 0 ? 
      arrSlidesFiltered.current.map((slide, i) => (
              <Slide
                key={slide.slide_id}
                id={slide.slide_id.toString()}
                slideNumber={i+1}
                totalSlides={arrSlidesFiltered.current.length}
                img = {slide.imgURL}
                caption={slide.imgAlt}
                display={slide.slide_id === slide_id_selected}
                onClick={handleSlide_Click}
              />
      )) : <div className="center-font">Sorry - No slides available</div>}
      

      {
      // If we have more than one slide show the previous button
      arrSlidesFiltered.current.length > 1 ? <div className="prev" onClick={handlePrev_Click}>&#10094;</div>: null}

      {
      // If we have more than one slide show the next button
      arrSlidesFiltered.current.length > 1 ? <div className="next" onClick={handleNext_Click}>&#10095;</div>: null}
      
    </div>
    <br />

    <div className="slideshow-dots center-font">
      {
      // Dots at the bottom so you can jump to slide
      arrSlidesFiltered.current.length > 1 ?
      arrSlidesFiltered.current.map((slide) => (
          <span 
            key={slide.slide_id} 
            id={slide.slide_id} 
            className={slide.slide_id === slide_id_selected ? "slideshow-dot active": "slideshow-dot" }
            onClick={handleDotThumbnail_Click} />
        )) :
        null
      }
    </div>
      
  </>
  );
      
}

export default SlideShow;


