// Custom modules
import http from "./httpService";
import { apiLogosURL } from "./config.json";

export async function getData() {
  // This is an array of company logos to display on various pages
  // This array will be shared via ContextPublic
  const results = await http.get(apiLogosURL);
  return results.data;
}

export default {
  getData,
};
