import React from "react";

// FlexLogos > logo
// (props are passed from parent)

const logo = ({ id, img, imgAlt, selected, onClick }) => {
  // return company logo for work page
  return (
    <img
      id={id}
      className={"companylogo" + (selected ? " selected" : "")}
      src={process.env.PUBLIC_URL + `${img}`}
      alt={imgAlt}
      onClick={onClick}
    />
  );
};

export default logo;
