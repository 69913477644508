// Node Modules
import React from "react";

const VisitModal = (props) => {

  // Props passed in from VisitorInfo
  // ==================================
  // props.handle_showDetails         (calls this function from the parent to close this component)
  // props.arrFields                  (an array of objects that allow us to show the data fields / properties we want)
  // props.data                       (an object showing the data we want to display in our modal)

  
  const {
        handle_Close,
        arrFields,
        data
        } = props;

// Returns the property value for the order number we pass in
const getPropertyValue = (order) => {
    let res = "";

    const o = arrFields.find(o => o.order === order)
    if(o){
        if(data && data.hasOwnProperty(o.columnName)) {
            res = data[o.columnName];
        }
    
        // Format timestamps
        if(new Date(res).getTime() > 0 && o.columnName === 'session_timestamp') {
            const date = new Date(res);
            res = date.toUTCString();
        }

        if(o.columnName === "is_eu") {
            res = res === 1 ? 'Yes' : 'No'
        }
    
        return res;
    }

    

  }

// Returns alias for the order number we pass in
const getAlias = (order) => {
    let res = "";

    const o = arrFields.find(o => o.order === order)
    if(o) res = o.alias;

    return res;
  }

const getTableHeader = () => {

return(
    <thead>
        <tr>
            <th>Field Name</th>
            <th>Value</th>
        </tr>
    </thead>

)

}

const getTableBody = () => {

return (
    <tbody>
        {
        // for each row...
        arrFields.map((row, i) => (
            <tr key={i}>
                <td>{getAlias(i+1)}</td>
                <td>{getPropertyValue(i+1)}</td>                        
            </tr>
        ))}
    </tbody>
)

}

// Close the Modal
const btnClose_onClick = () => {
handle_Close()
}

  return (
    <div id="myModal" className="modal">
      <div className="modal-content">
        <div className="top-bar">
          <div id="btnClose" onClick={btnClose_onClick}>
            &times;
          </div>
        </div>
        
        <div className="modal-body">
            <h3 className="center-font">Visitor Details</h3>
            <table className="standard no-hover">
                {getTableHeader()}
                {getTableBody()}
            </table>
        </div>
        
      </div>
    </div>
  );
}

export default VisitModal;
