import React from "react";
import { useHistory } from "react-router-dom";

// Images
import img404 from "../images/standard/Not Found.svg"

export function NotFound () {

  const history = useHistory()

  const handle_buttonClick = () => {
    history.push("/");
  }

  return (
    <>
      <div className="nf-verticalhorizontal">
      <img className="nf" src={img404} alt="404 - Not Found" />
        <h2 className="center-font">Whoops! Page Not Found</h2>
        <p className="center-font">Don't panic - you just stumbled across a missing page or unknown URL on our site. Time to go back to the home page...
        </p>
        <p>
        <button 
            className="standard"  
            title="Home"
            onClick={handle_buttonClick}>
            Home
        </button>     
        </p>
        
      </div>
    </>
  );
}

export default NotFound;
