import React from "react";


const Table = (props) => {
  
  const { 
      id
    , arrData
    , arrShowColumns
    ,handle_rowClick} = props;



  // Returns the property value for the object based on unique RID
  const getPropertyValue = (RID, propertyName) => {
    let res

    const o = arrData.find(o => o.RID === RID)
    if(o && o.hasOwnProperty(propertyName)) {
        res = o[propertyName];
    }

    // Format timestamps
    if(new Date(res).getTime() > 0) {
        const date = new Date(res);
        res = date.toUTCString();
    }

    return res;

  }

  const getTableHeader = () => {

    return(
      <thead>
          <tr>
              {arrShowColumns && arrShowColumns.length > 0 ? arrShowColumns.map((o,i) => <th key={i}>{o.alias}</th>) : null}
          </tr>
      </thead>

    )

}

  const getTableBody = () => {

    return (
        <tbody>
            {
            // for each row...
            arrData.map((row) => (
                <tr id={row.RID} key={row.RID} onClick={handle_rowClick}>
                { 
                // for each column we want...
                arrShowColumns.map((col,col_key) => (
                    <td key={col_key}>
                        {
                        // Show the property value in the cell
                        getPropertyValue(row.RID,col.columnName)
                        }
                    </td>
                ))                        
                }
                </tr>
            ))}
        </tbody>
    )

  }

  return ( 
    
    <table id={id} className="standard">
        {getTableHeader()}
        {getTableBody()}
    </table>
  );
}

export default Table;