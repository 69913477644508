import React from "react";

// Home > FlexLogos > logo
// (props are passed from parent)

const logo = ({ img, imgAlt }) => {

  // return company logo for home page
  return <img className="companylogo-home" src={process.env.PUBLIC_URL + `${img}`} alt={imgAlt} />;
};

export default logo;
