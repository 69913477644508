import React from "react";

// SlideShow > Slide
// (props are passed from parent)

const slide = ({ id, slideNumber, totalSlides, img, caption, display, onClick }) => {
  // return a screenshot slide
  return (
    <div className="slideshow-image-container fade" style={{display: display ? "block" : "none"}}>
      <div className="slideshow-number">{slideNumber} / {totalSlides}</div>
      <img
        id={id}
        className="slide-image"
        alt={`Slide ${slideNumber} of ${totalSlides}`}
        src={process.env.PUBLIC_URL + `${img}`}
        onClick={onClick}
      />
      <div className="slideshow-caption">{caption}</div>
  </div>
  );
};

export default slide;