import React from "react";
import { Link } from "react-router-dom";

const Blurb = () => { 

  return (
      <>
            <h2 className="center-font">About Us</h2>
            <div className="center-font about-blurb">
                <p>
                <strong>We love creating solutions that save you time, effort and money!</strong>
                </p>
                <p>
                    We have over 15 years experience coding, optimising and solving problems to deliver exactly what you need.&nbsp;
                     <strong><Link className="normal" to="/services">See what we can do</Link></strong> - it could be anything from design work and custom images, app, web and database development through to data analysis using complex optimisation and mathematical modelling.
                </p>
                <p>
                    We have worked on all sorts of interesting projects using various technologies listed below - <strong><Link className="normal" to="/work">check out some examples</Link></strong>.
                </p>
                <p>
                    If you think we can help, <strong><Link className="normal" to="/contact">send us a message</Link></strong> and we'll get back to you. <span role="img" aria-label="Smiling Face Emoji">&#128512;</span> 
                </p>
            </div>
    </>
  );

};

export default Blurb;