// Node Modules
import React, {useState, useRef, useEffect} from "react";

// Components
import Fields from "../common/fields";

// Services
import {createUser} from "../../services/userService";

// Custom Modules
import {toastError} from "../../js/toast/toast"
import getRef from "../../js/getRefs";

// Images
import imgUser from "../../images/standard/user.svg";

function UserNew(props) {
  
    // PROPS
    const {currentUser} = props

    // STATE
    const [state, setstate] = useState({
        blnEnableButton: false,
        showComplete: false,
        arrFields: [
            {
                id: "txtUsername",
                fieldType: "Input",
                type: "text",
                tabIndex: "1",
                blnFullWidth: true,
                blnShowTick: false,
                txtLabel: "Username:",
                txtPlaceholder: "Enter Username",
                minLength: 3,
                maxLength: 100,
                blnRequired: true,
            },
            {
                id: "txtPassword",
                fieldType: "Input",
                type: "password",
                tabIndex: "2",
                blnFullWidth: true,
                blnShowTick: false,
                txtLabel: "New Password:",
                txtPlaceholder: "Enter Password",
                minLength: 8,
                maxLength: 255,
                blnRequired: true,
            },
            {
                id: "txtPasswordConfirm",
                fieldType: "Input",
                type: "password",
                tabIndex: "3",
                blnFullWidth: true,
                blnShowTick: false,
                txtLabel: "Confirm New Password:",
                txtPlaceholder: "Re-enter Password",
                minLength: 8,
                maxLength: 255,
                blnRequired: true,
            },
            {
                id: "chkActive",
                fieldType: "Checkbox",
                tabIndex: "4",
                blnFullWidth: false,
                blnShowTick: false,
                txtLabel: "Account Active",
                checked: false,
                disabled: false,
            },
            {
                id: "chkAdmin",
                fieldType: "Checkbox",
                tabIndex: "5",
                blnFullWidth: false,
                blnShowTick: false,
                txtLabel: "Admin (Can create / modify users)",
                checked: false,
                disabled: false,
            }
        ]
    });

    //REFS
    // A list of refs for each rendered element for dynamically created fields
    // (fields created are based on the arrFields array shown above)
    // When the item is rendered a reference to the field is created via <Fields/>
    const refArrFieldRefs = useRef([]);

    // GENERIC FUNCTIONS
    // Creates the body object for our HTTP request
    const createBodyObject =() => {
    
        const body = {};

        // Set the properties of our movie object to the field info values
        body.username = getRef(refArrFieldRefs.current, "txtUsername").current.value;
        body.password = getRef(refArrFieldRefs.current, "txtPassword").current.value;
        body.is_active = getRef(refArrFieldRefs.current, "chkActive").current.checked;
        body.is_admin = getRef(refArrFieldRefs.current, "chkAdmin").current.checked;
        body.modified_by = `${currentUser.firstName ? currentUser.firstName : ""} ${currentUser.lastName ? currentUser.lastName : ""}`
    
        return body;
    }

    // Function updates the field indicators based on an array of fields passed in
    // (it also determines if the form button should be enabled as well)
    const updateFieldIndicators = (...args) => {

        // Obtain a list of references for the field IDs passed in
        const arrFields = []
        args.forEach(fieldID => {
            arrFields.push(getRef(refArrFieldRefs.current,fieldID).current)
        })

        // make a copy of our array of objects held by the state
        let arrCopy = [...state.arrFields];

        arrFields.forEach(f => {
            // find our object from array that corresponds to the target field that just changed
            // (variable is a pointer to the object in the copied array - not a copy of the object)
            let o = arrCopy.find(o => o.id === f.id)

            // if required length in field has been met then we should show the tick image
            if(o.fieldType === "Input") {
                let showTick = (f.value.length >= Number(o.minLength) 
                && f.value.length !== 0)

                // If we have altered the showTick flag then update the state
                if (showTick !== o.blnShowTick) {
                // updated object with new flag
                o.blnShowTick = showTick;
                
                }
            }
        })

        // Do we enable the submit button (every required field must be completed correctly)
        const arrRequired = arrCopy.filter(o => o.blnRequired === true);
        const blnEnableButton = (arrRequired.filter(o => o.blnShowTick === false).length === 0)

        // Update the state so it can re-render
        setstate({...state, 
            blnEnableButton,
            arrFields: arrCopy
        });
        
    }


    // EVENTS
    // Every time a field is altered this will run...
    const handleField_Change = (e) => {
    
        // Update the field indicator for the field that is being changed
        // (also figures out if save button should be enabled)
        updateFieldIndicators(e.currentTarget.id)
       
    }

    // When the user saves the user details...
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Make sure our confirmed password matches
        const refConfirmed = getRef(refArrFieldRefs.current, "txtPasswordConfirm")

        if (getRef(refArrFieldRefs.current, "txtPassword").current.value !== refConfirmed.current.value) {
            toastError("The password mismatches the confirmed password (case sensitive). Try again...")
            refConfirmed.current.value = "";
            refConfirmed.current.focus();
            updateFieldIndicators("txtPasswordConfirm");
            return;
        }

        // This is the body of our request sent to the back end
        const body = createBodyObject();

        // await for the server response from back end
        const res= await createUser(body);

        if(res.successful){

            // Clear our references to the fields as they will no longer be displayed
            // (This allows us to recreate our refs again in the next render via <Fields> component, 
            // otherwise the field refs will remain in memory but they all become null as they are no longer available in the DOM)
            refArrFieldRefs.current = []; 

            // Run this method which is from the parent component
            // (this updates the list of names and rerenders this component to show completed HTML)
            setstate({...state,showComplete:true})


        }else {
            toastError(`Cannot create user account`,res.status, res.response);
        }
    }

    // Load: Simulates componentDidMount() method.
    // This will only run once - when the component has mounted
    useEffect(() => {

        // Set the focus to the first input field
        const ref = getRef(refArrFieldRefs.current, state.arrFields.find(o => o.tabIndex === "1").id)
        if(ref) ref.current.focus();
    
    },[])


    return (
        <>
            {
            state.showComplete ? 
            <div>
                <img className="center-horizontally medium" src={imgUser} alt="user" />
                <h3 className="center-font">User Account Successfully Created</h3>
            </div>
             :
            <form 
            className="form-container"
            onSubmit={handleSubmit}>
                
                <Fields
                    refArrFieldRefs = {refArrFieldRefs}
                    arrFields = {state.arrFields}
                    onChange = {handleField_Change}
                />
                <button 
                    className={`standard ${!state.blnEnableButton ? "disabled" : ""}`}  
                    disabled={!state.blnEnableButton}
                    type="submit"
                    tabIndex="99">
                    Save
                </button>
            </form>     
            }
        </>
    );

}

export default UserNew;