// Node Modules
import jwtDecode from "jwt-decode";

//  Custom Modules
import http from "./httpService";
import { apiLoginURL, apiLogoutURL, apiResetURL, storage } from "./config.json";

// LOGIN
// Save user JWT info to the browsers local storage
export async function login(credentials) {
  let response = { loggedIn: false };

  try {
    const res = await http.post(apiLoginURL, credentials);

    if (res.status === 200) {
      // It must come back with a valid JWT we can decode
      const jwtDecrypted = jwtDecode(res.data);

      // store this token in the browsers local storage as a key value pair
      if (jwtDecrypted) {
        localStorage.setItem(storage.apiAuthResponse, res.data);

        response = { loggedIn: true };
      }
    } else {
      response = {
        loggedIn: false,
        status: res.status,
        response: res.data,
      };
    }
  } catch (err) {
    response = {
      loggedIn: false,
      status: err.response.status,
      error_message: err.message,
      response: err.response.data,
    };
  }

  return response;
}

// LOGOUT
// Function removes the token from the browsers local storage
export async function logout() {
  try {
    // This get request removes the secure cookie that enables logging in (completed via back end)
    const res = await http.get(apiLogoutURL);

    // If server has responded with successful logout then it will return an object
    // loggedIn: false
    // (You can remove the local storage details about the user)
    if (res.status === 200) {
      if (!res.data.loggedIn) {
        localStorage.removeItem(storage.apiAuthResponse);
        return true;
      }
    }
  } catch (err) {
    return false;
  }
  return false;
}

// RESET PASSWORD
export async function reset(body) {
  let response = { updated: false };

  try {
    // This get request removes the secure cookie that enables logging in (completed via back end)
    const res = await http.put(apiResetURL, body);

    if (res.status === 200) {
      response = { updated: true };
    } else {
      response = {
        updated: false,
        status: res.status,
        response: res.data,
      };
    }
  } catch (err) {
    response = {
      updated: false,
      status: err.response.status,
      error_message: err.message,
      response: err.response.data,
    };
  }

  return response;
}

// Function returns the browser token which contains
// information about the user. The token only
// appears after running login method
function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(storage.apiAuthResponse);

    // return the object by decoding the JSON web token
    const user = jwtDecode(jwt);

    // Only return the user object if the expiry date has not lapsed
    if (new Date().getTime() < Date.parse(user.expires)) return jwtDecode(jwt);
    else {
      return null;
    }
  } catch (err) {
    // if we do not have a web token then just ignore the error.
    // tokens only appear in browser after logging in or registering.
    return null;
  }
}

export default {
  getCurrentUser,
};
