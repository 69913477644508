// Node Modules
import React from "react";

const TableSmall = (props) => {

    const {
          arrData
        , arrShowColumns
        , handle_rowClick
    } = props;


    // Returns the property value for the object based on unique RID
    const getPropertyValue = (RID, propertyName) => {
        let res

        const o = arrData.find(o => o.RID === RID)
        if(o && o.hasOwnProperty(propertyName)) {
            res = o[propertyName];
        }

        // Format timestamps
        if(new Date(res).getTime() > 0) {
            const date = new Date(res);
            res = date.toUTCString();
        }

        return res;

    }

    const getTableBody = (visit) => {

        return (
            <tbody>
            {              
                // go through each column we want and show them as rows...
                arrShowColumns.map((o,i) => {
                    return (
                        <tr key={i} id={visit.RID} onClick={handle_rowClick}>
                            <td>{o.alias}</td>
                            <td>{getPropertyValue(visit.RID, o.columnName)}</td>                        
                        </tr>
                    )
                })
            }
            </tbody>
        )

    }

    return (
        // for each row (object in array of data) we make a new table...
        arrData.map((visit, i) => {
            return (
                <table key={i} id={visit.RID} className="small">
                    {getTableBody(visit)}
                </table>
            )
        })
    );

}

export default TableSmall;