// Node Modules
import React, { Component } from "react";

// Components
import Card from "./card";

// Home > Flexcards > Card
// (props are passed from parent)

class FlexCards extends Component {
  render() {
    const { arrCards } = this.props;

    return (
      <div className="flex-cards">
        {arrCards
          ? arrCards.map((card) => (
              <Card
                key={card.id}
                url={card.url}
                img={card.img}
                imgAlt={card.imgAlt}
                header={card.header}
                text={card.text}
              />
            ))
          : null}
      </div>
    );
  }
}

export default FlexCards;
