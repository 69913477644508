// Node Modules
import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";

// Components - all pages
import TopBar from "../components/common/topBar";
import ScrollToTop from "../components/common/scrollToTop";
import Footer from "../components/common/footer";

// Components - route pages
import MainRoute from "../components/common/mainRoute";
import LoginRoute from "../components/common/loginRoute";
import ProtectedRoute from "../components/common/protectedRoute";
import Work from "../components/work";
import NotFound from "../components/notFound";
import Contact from "../components/contact";
import AboutUs from "../components/about";
import Services from "../components/services";
import TermsAndConditions from "../components/termsandconditions";
import PrivacyPolicy from "../components/privacypolicy";
import Login from "../components/login";
import Admin from "../components/admin";

//  Custom Modules
import { recordVisit } from "./visits";

const App = () => {
  // (This will only run once - when the component has mounted)
  useEffect(() => {
    recordVisit();
  }, []);

  return (
    <div id="body-container">
      <ToastContainer />
      <ScrollToTop />
      <TopBar />

      <div className="main-content">
        <Switch>
          <Route path="/work" component={Work} />
          <Route path="/about" component={AboutUs} />
          <Route path="/services" component={Services} />
          <Route path="/contact" component={Contact} />
          <Route path="/terms" component={TermsAndConditions} />
          <Route path="/privacy" component={PrivacyPolicy} />
          <Route path="/not-found" component={NotFound} />
          <LoginRoute path="/login" component={Login} />
          <ProtectedRoute path="/admin" component={Admin} />
          <MainRoute path="/home" />
          <MainRoute exact path="/" />
          <Redirect to="/not-found" />
        </Switch>
      </div>

      <Footer />
    </div>
  );
};

export default App;
