// Node Modules
import React, {useState, useEffect, useRef} from "react";

// Custom Modules
import {login} from "../services/authService";
import {toastError} from "../js/toast/toast";
import getRef from "../js/getRefs";


// Components
import Fields from "./common/fields"


function Login (props) {

    // STATE
    const [state, setstate] = useState({
        bgColour: "var(--form-page-main-bg-color)",
        blnEnableButton: false,
        arrFields: [
            {
                id: "txtUsername",
                fieldType: "Input",
                type: "text",
                tabIndex: "1",
                blnFullWidth: true,
                blnShowTick: false,
                txtLabel: "Username:",
                txtPlaceholder: "Enter Your Username",
                minLength: "3",
                maxLength: "255",
                blnRequired: true
            },
            {
                id: "txtPassword",
                fieldType: "Input",
                type: "password",
                tabIndex: "2",
                blnFullWidth: true,
                blnShowTick: false,
                txtLabel: "Password:",
                txtPlaceholder: "Enter Your Password",
                minLength: "8",
                maxLength: "255",
                blnRequired: true
            },
        ]
    });

    // A list of refs for each rendered element for dynamically created fields
    // (fields created are based on the arrFields array shown above)
    // When the item is rendered a reference to the field is created via <Fields/>
    const refArrFieldRefs = useRef([]);

    // GENERIC FUNCTIONS
    const createCredentialsObject =() => {
    
        const credentials = {};

        // Set the properties of our movie object to the field info values
        credentials.username = getRef(refArrFieldRefs.current, "txtUsername").current.value;
        credentials.password = getRef(refArrFieldRefs.current, "txtPassword").current.value;
    
        return credentials;
    }

    // EVENTS
    const handleSubmit = async (e) => {
        e.preventDefault();

        const credentials = createCredentialsObject();

        const result = await login(credentials);

        // If we were redirected to this page this will be set so we can go back to it after logging on
        // (This is set via protectedRoute.jsx)
        const pls = props.location.state

        if(result.loggedIn){
            // (windows.location forces a full reload of our application
            // which will trigger the App.js to run so the topbar can update with the correct options)
            // If we have a state object then redirect to where we originally wanted to go to
            // otherwise just go directly to the admin page
            window.location = pls && pls.hasOwnProperty('redirectedFromPath') ? pls.redirectedFromPath : "/admin";
        }else {
            toastError(`Cannot log on`, result.status, result.response);

            // Clear the password (ref is a reference to this field)
            const ref = getRef(refArrFieldRefs.current, "txtPassword")
            ref.current.value = ""
            ref.current.focus();

            // We now need to update the tick status for this field so it doesn't get rendered
            // make a copy of our array of objects held by the state
            let arrCopy = [...state.arrFields];

            // find our object from array that corresponds to the target field that just changed
            // (variable is a pointer to the object in the copied array - not a copy of the object)
            let o = arrCopy.find(o => o.id === "txtPassword")
            o.blnShowTick = false;

            // Update the state so it can re-render
            setstate({...state, 
                blnEnableButton: false,
                arrFields: arrCopy
                });
        }
    }
    
    const handleField_Change = (e) => {
    
        // This is our target field being changed
        const target = e.currentTarget

        // make a copy of our array of objects held by the state
        let arrCopy = [...state.arrFields];

        // find our object from array that corresponds to the target field that just changed
        // (variable is a pointer to the object in the copied array - not a copy of the object)
        let o = arrCopy.find(o => o.id === target.id)

        // if required length in field has been met then we should show the tick image
        let showTick = (target.value.length >= Number(o.minLength) 
                        && target.value.length !== 0)

        // If we have altered the showTick flag then update the state
        if (showTick !== o.blnShowTick) {
            // updated object with new flag
            o.blnShowTick = showTick

            // Do we enable the submit button (every required field must be completed correctly)
            const arrRequired = arrCopy.filter(o => o.blnRequired === true);
            const blnEnableButton = (arrRequired.filter(o => o.blnShowTick === false).length === 0)

            // Update the state so it can re-render
            setstate({...state, 
                blnEnableButton,
                arrFields: arrCopy
              });
        }
    }

    // Load: Simulates componentDidMount() method.
    // This will only run once - when the component has mounted
    useEffect(() => {
        // This element is from a higher order so I cannot override it using CSS as it will 
        // make the change across all the components (since CSS is global and overwrites properties for all components / pages)
        document.getElementById("body-container").style.backgroundColor = state.bgColour;

        // Scroll to the top
        window.scrollTo(0, 0);

        // Set the focus to the first input field
        const ref = getRef(refArrFieldRefs.current, "txtUsername")
        ref.current.focus();

        // Unload: Simulates componentWillUnmount() method.
        // Cleanup abilities when this component is removed from the DOM
        return () => {
            const BodyContainerStyle = document.getElementById("body-container").style;

            if(BodyContainerStyle.backgroundColor)
                BodyContainerStyle.removeProperty("background-color");
        }
        
    },[])

    return (

        <div className="form-card">
            <h2 className="center-font">Login</h2>
            <form 
            id="login-form"
            className="form-container"
            onSubmit={handleSubmit}>
                <Fields
                    refArrFieldRefs = {refArrFieldRefs}
                    arrFields = {state.arrFields}
                    onChange = {handleField_Change}
                />
                <button 
                    className={`standard ${!state.blnEnableButton ? "disabled" : ""}`}  
                    disabled={!state.blnEnableButton}
                    type="submit"
                    tabIndex="3">
                    Login
                </button>
            </form>           
        </div>

    )

}

export default Login