import React, {forwardRef} from "react";

// Shows information as a field without a background (read-only values to be displayed on a form)
const InfoField = forwardRef((props,ref) => {
  
  const { 
      id
    , noAnimation
    , blnFullWidth
    , txtLabel
    , value} = props;

  return (
    <div className={`field-container nobackground
                  ${blnFullWidth ? "fullwidth" : "halfwidth"}
                  ${noAnimation ? "no-animation" : ""}`}>
        <span className="field-label">{txtLabel}</span>
        <div id={id} ref={ref}>{value}</div>
    </div>
  );
  
});

export default InfoField;