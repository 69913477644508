import React from "react";

const SectionHeader = (props) => { 

    const {
        key,
        myRef,
        imgURL,
        imgAlt,
        text,
        imgInvertColours,
        highlight
    } = props;

    return (
        <div key={key} ref={myRef} className={"section-header " + (highlight ? "highlight": "")}>
            <img className={imgInvertColours ? "invert" : null} src={process.env.PUBLIC_URL + `${imgURL}`} alt={imgAlt} />
            <div className="text">{text}</div>
        </div>
    );

};

export default SectionHeader;