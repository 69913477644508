// Node Modules
import React, {useState, useRef, useEffect} from "react";

// Services (Obtain Data etc)
import svcProfiles from "../services/profileService"
import svcSkills from "../services/skillsService";
import svcSkillsPeople from "../services/skillsPeopleService";

// Components
import Blurb from "./about/blurb";
import SectionHeader from "./common/sectionHeader";
import SectionSkills from "./about/sectionSkills";
import Profile from "./about/profile";

// Images
import imgTech from "../images/standard/Icon Tech.svg";


function AboutUs () { 

    // REFS (Component variables) 
    // I've added them here in the parent component because it will only trigger getData() once
    // when the page loads - not every time the SectionSkills is rendered.
    const arrProfiles = useRef([]);
    const arrItems = useRef([]);
    const arrSkillsPeople = useRef([]);
    const arrItemsCategorised = useRef([]);
    const refSkillsSection = useRef(null);

    // STATE
    const [state, setstate] = useState({
        arrPerson_id_selected: []
    });

    // GENERIC FUNCTIONS
    const getSkillsEnabled = () => {

        if (state.arrPerson_id_selected.length === 0) {
            return arrSkillsPeople.current.map(o => o.skill_id);
        }
        else {
            return arrSkillsPeople.current.filter(o => state.arrPerson_id_selected.includes(o.person_id))
                                            .map(o => o.skill_id);
        }
    }

    const getFirstNamesSelected = () => {

        let arrFirstNames = [];
        let result = "";

        // Show all the names as no filter (Jim, Bob and Sarah)
        if(state.arrPerson_id_selected.length === 0) {
            arrFirstNames = arrProfiles.current.map(o => o.firstName)
                                                .sort()      
            result = arrFirstNames.slice(0,-1).join(", ") + " and " + arrFirstNames.slice(-1); 
        } 
        // Show the only selected name (Jim)
        else if(state.arrPerson_id_selected.length === 1) {
            result = arrProfiles.current.filter(o => state.arrPerson_id_selected.includes(o.person_id))
                                        .map(o => o.firstName)
        }
        // Show just the selected names (Jim and Sarah)
        else {
            arrFirstNames = arrProfiles.current.filter(o => state.arrPerson_id_selected.includes(o.person_id))
                                            .map(o => o.firstName)
                                            .sort()      
            result = arrFirstNames.slice(0,-1).join(", ") + " and " + arrFirstNames.slice(-1);          
        }

        return result;
    }

    // This function splits all the skills into separate arrays based on category
    const getarrItemsCategorised = () => {

        // Get an array of unique categories
        const arrCategories = [...new Set(arrItems.current.map(o => o.category))];

        // For each unique category
        arrCategories.forEach(o => {
            // Put only these categorised items into our categorised object array
            arrItemsCategorised.current.push(arrItems.current.filter(item => item.category === o))
        })
    }

    // EVENTS
    // When component loads...
    useEffect(() => {
        async function getAPIData () {
             // REFS: Set values for this component (obtain the data always used / displayed)
            // arrProfiles is an array of profile data to be rendered on the page
            arrProfiles.current = await svcProfiles.getData();

            // arrItems is an array of individual skills we can select and choose from
            arrItems.current = await svcSkills.getData();

            // arrSkillsPeople is an array listing all the skills each person has
            arrSkillsPeople.current = await svcSkillsPeople.getData();

            // Update the state so the page renders
            setstate({...state});
          }

        // Scroll to the top
        window.scrollTo(0, 0);
      
         getAPIData()
      }, [])

    const handle_techFilterClick = (e) => {

        const arr = state.arrPerson_id_selected;
        const id = Number(e.currentTarget.id);

        // If we don't have this id selected, add it. Otherwise remove it.
        if(arr.includes(id)) {
            // Remove this person_id from the array so skills altered
            arr.splice(arr.indexOf(id),1)
        } else {
            // Add our person_id to our array so we can see this persons skills
            arr.push(id)
            // Scroll to the skills section (this has a ref)
            refSkillsSection.current.scrollIntoView(true);
        }

        setstate({arrPerson_id_selected: arr});
    }

    // Get the first names of those we have selected
    const selectedFirstNames = getFirstNamesSelected();

    // Before we return any HTML, figure out which skills should be enabled
    // as this is determined by the person we have selected (show everything if no one is selected)
    const arrSkill_ids_enabled = getSkillsEnabled();

    // our array of items needs to be separated into multiple arrays based on 
    // the category (each skills card is a different category)
    if(arrItemsCategorised.current.length === 0)
    {
       getarrItemsCategorised();
    }

    return (
        <>
            <div className="middle-content">
                <Blurb />
                {arrProfiles.current 
                    ? arrProfiles.current.map(o => (
                    <Profile 
                        key={o.person_id}
                        person_id={o.person_id}
                        personName={`${o.firstName} ${o.lastName}`}
                        email={o.email}
                        linkedIn={o.linkedIn}
                        profileText={o.profileText}
                        imgURL={o.imgURL}
                        handle_techFilterClick={handle_techFilterClick}
                        techButtonSelected = {state.arrPerson_id_selected.includes(o.person_id)}
                        showClock={o.showClock}
                    />
                    ))
                : null}
                <SectionHeader 
                    myRef={refSkillsSection}
                    imgURL={imgTech}
                    imgAlt="Tech Skills"
                    text={`Technologies (${selectedFirstNames})`}
                />
                <SectionSkills 
                    arrItemsCategorised={arrItemsCategorised.current}
                    arrSkill_ids_enabled={arrSkill_ids_enabled}
                />
            </div>
        </>
    )
}

export default AboutUs