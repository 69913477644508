// Node Modules
import React, {useEffect, useRef, createRef} from "react";
import Chart from "chart.js";


// https://www.chartjs.org/docs/latest/

const LineChart = (props) => {

    // PROPS
    const {id, arrData, xAxisUnit, smallWindow} = props

    // REFS
    const refCanvas = createRef();
    const refLineChart = useRef();
    

    // GENERIC FUNCTIONS
    const buildChart = () => {

        // This is refering to our canvas element
        const myChartRef = refCanvas.current.getContext("2d");
        
        if(typeof refLineChart.current !== "undefined") refLineChart.current.destroy();

        refLineChart.current = new Chart(myChartRef, {
            type: "line",
            data: {
                // Bring in data
                labels: arrData.map(o => o.date),
                datasets: [
                    {
                        label: "Site Visits",
                        data: arrData.map(o => o.value),
                        borderColor: "#1565c0",
                        borderWidth: 3,
                        backgroundColor: "rgba(2, 143, 190, 0.4)",
                        radius: smallWindow ? 2 : 5,
                        hoverRadius: 10
                        
                    }
                ]
            },
            options: {
                maintainAspectRatio: false,
                legend: {
                    display: false
                },
                scales: {
                    xAxes: [
                      {
                        type: 'time',
                        time: {
                          unit: xAxisUnit
                        }
                      }
                    ],
                    yAxes: [
                      {
                        ticks: {
                          min: 0
                        }
                      }
                    ]
                },
                tooltips: {
                    backgroundColor: '#028fbe',
                    titleFontFamily: "Montserrat-SemiBold",
                    titleFontSize: 16,
                    bodyFontFamily: "Montserrat-Regular",
                    bodyFontSize: 16,
                    xPadding: 10,
                    yPadding: 10,

                }
            }
        });
    }

    // EVENTS
    // Component mounts...
    useEffect(() => {
        buildChart();      
    },[]);

    useEffect(() => {
        buildChart();      
    },[arrData]);

    return (
        <div className="chart-container">
            <canvas
                id={id}
                ref={refCanvas}
            />
        </div>
    );
};

export default LineChart;