import React, {useState, useEffect} from "react";

const Clock = (props) => { 


    // STATE
    const [state, setState] = useState({
        secDegrees: 0,
        minDegrees: 0,
        hourDegrees: 0
    });

    useEffect(() => {

        // When component loads set interval to run every second updating the state
        const timer = setInterval(() => {

          const now = new Date();
          const seconds = now.getSeconds();
          const minutes = now.getMinutes();
          const hour = now.getHours();

          const secDegrees = ((seconds / 60) * 360) + 90;
          const minDegrees = ((minutes / 60) * 360) + ((seconds/60)*6) + 90
          const hourDegrees = ((hour / 12) * 360) + ((minutes/60)*30) + 90;

          setState({secDegrees, minDegrees, hourDegrees });
        }, 1000);

        // When component unloads clear the timer
        return () => clearInterval(timer);
      }, []);

    return (
        <>
            <div className="clock">
                <div className="clock-numbers">
                    <div className="marking marking-one"></div>
                    <div className="marking marking-two"></div>
                    <div className="marking marking-three"></div>
                    <div className="marking marking-four"></div>
                    <div className="inner-clock-face">
                    <div className="hand hour-hand" style={{transform: `rotate(${state.hourDegrees}deg)`}}></div>
                    <div className="hand min-hand" style={{transform: `rotate(${state.minDegrees}deg)`}}></div>
                    <div className="hand second-hand" style={{transform: `rotate(${state.secDegrees}deg)`}}></div>
                    </div>
                </div>
            </div> 
            
        </>
    );

};

export default Clock;