// Node Modules
import React from "react";

// Components
import Input from "./input";
import TextArea from "./textArea";
import Select from "./select"
import Checkbox from "./checkbox"
import InfoField from "./infoField"

// Custom Modules
import getRef from "../../js/getRefs"

// Images
import imgTick from "../../images/standard/Tick.svg"

// Function renders the type of field you want based on array of objects
const Fields = (props) => {

    const {arrFields, onChange, refArrFieldRefs} = props;

    return (
      <>
      {
        arrFields.map(o => {

            // We don't need to make a reference for InfoFields
            const ref = getRef(refArrFieldRefs.current, o.id, true)
  
            switch (o.fieldType) {
              case "Input" :
                return  <Input
                          key={o.id}
                          id={o.id}
                          ref={ref}
                          type={o.type}
                          tabIndex={o.tabIndex}
                          blnFullWidth={o.blnFullWidth}
                          blnShowTick={o.blnShowTick}
                          txtLabel={o.txtLabel}
                          txtPlaceholder={o.txtPlaceholder}
                          minLength={o.minLength}
                          maxLength={o.maxLength}
                          blnRequired={o.blnRequired}
                          imgFieldIndicator={imgTick}
                          onChange={onChange}
                          />

              case "TextArea" : 
                return <TextArea
                          key={o.id}
                          id={o.id}
                          ref={ref}
                          tabIndex={o.tabIndex}
                          blnFullWidth={o.blnFullWidth}
                          blnShowTick={o.blnShowTick}
                          txtLabel={o.txtLabel}
                          txtPlaceholder={o.txtPlaceholder}
                          minLength={o.minLength}
                          maxLength={o.maxLength}
                          blnRequired={o.blnRequired}
                          imgFieldIndicator={imgTick}
                          onChange={onChange}
                          />

              case "Select":
                return <Select
                          key={o.id}
                          id={o.id}
                          ref={ref}
                          noAnimation={o.noAnimation}
                          tabIndex={o.tabIndex}
                          blnFullWidth={o.blnFullWidth}
                          blnShowTick={o.blnShowTick}
                          txtLabel={o.txtLabel}
                          blnRequired={o.blnRequired}
                          imgFieldIndicator={imgTick}
                          onChange={onChange}
                          arrSelectOptions={o.arrSelectOptions}
                          />

              case "Checkbox":
                return <Checkbox
                          key={o.id}
                          id={o.id}
                          ref={ref}
                          noAnimation={o.noAnimation}
                          tabIndex={o.tabIndex}
                          blnFullWidth={o.blnFullWidth}
                          blnShowTick={o.blnShowTick}
                          txtLabel={o.txtLabel}
                          onChange={onChange}
                          checked={o.checked}
                          disabled={o.disabled}
                          />

              case "InfoField": 
                return <InfoField
                          key={o.id}
                          id={o.id}
                          ref={ref}
                          noAnimation={o.noAnimation}
                          blnFullWidth={o.blnFullWidth}
                          txtLabel={o.txtLabel}
                          value={o.value}
                        />

              default: return null;
                }       
              }
      )
      }
      </>
  );
};

export default Fields
