// Node Modules
import React, {useState, useEffect, useRef} from "react";

// Components
import Fields from "./common/fields";

// Custom Modules
import {toastError} from "../js/toast/toast";
import getRef from "../js/getRefs";
import {sendMessage} from "../services/messageService"

function Contact () {

    // STATE
    const [state, setstate] = useState({
        bgColour: "var(--form-page-main-bg-color)",
        blnFormComplete: false,
        blnEnableSubmitButton: false,
        blnAnimationFinished: false,
        arrFields: [
            {
                id: "contactName",
                fieldType: "Input",
                type: "text",
                tabIndex: "1",
                blnFullWidth: true,
                blnShowTick: false,
                txtLabel: "Full Name:",
                txtPlaceholder: "Enter Your Name",
                minLength: "3",
                maxLength: "255",
                blnRequired: true
            },
            {
                id: "contactEmail",
                fieldType: "Input",
                type: "email",
                tabIndex: "2",
                blnFullWidth: false,
                blnShowTick: false,
                txtLabel: "Email:",
                txtPlaceholder: "Enter Your Email Address",
                minLength: "5",
                maxLength: "255",
                blnRequired: true
            },
            {
                id: "contactPhone",
                fieldType: "Input",
                type: "text",
                tabIndex: "3",
                blnFullWidth: false,
                blnShowTick: false,
                txtLabel: "Phone:",
                txtPlaceholder: "Enter Your Phone Number",
                minLength: "10",
                maxLength: "25",
                blnRequired: false
            },
            {
                id: "contactMsg",
                fieldType: "TextArea",
                tabIndex: "4",
                blnFullWidth: true,
                blnShowTick: false,
                txtLabel: "Message:",
                txtPlaceholder: "Enter Your Message...",
                minLength: "10",
                maxLength: "1024",
                blnRequired: true
            }
        ]
    });

    //  REFS
    // We use these references to point to elements of this component
    // (used for adding classes when the button is clicked / form is submitted)
    const refBlurb = useRef();
    const refFormContainer = useRef();
    const refButtonSubmit = useRef();
    const refEnvHiddenBG = useRef();
    const refEnvelope = useRef();
    const refEnvLid = useRef();
    const refFormContainerHeight = useRef();

    // A list of refs for each rendered element for dynamically created fields
    // (fields created are based on the arrFields array shown above)
    const refArrFieldRefs = useRef([]);


    // GENERIC FUNCTIONS
    const validateValue = (type, myValue) => {

        switch(type){
            case "email": 
                // regular expression for validating email address
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(myValue).toLowerCase());
            default:
                return true;
        }
    } 

    const getBlurb = (completed) => {
        if(completed){
            return {__html: "<strong>Thanks for your message! We will get back to you soon.</strong><br /><p>Look out for a confirmation email<br/>(it may accidentally end up in your junk folder).</p>"};
        }
        else{
            return {__html: "Complete the form below and we will get back to you pronto..."};
        }
    }

    const envelopeLid_onAnimationEnd = () => {
        setstate({blnAnimationFinished: true})
    }


    const createBodyObject = () => {

        const result = {};

        // Set the properties of our movie object to the field info values
        result.fullName = getRef(refArrFieldRefs.current, "contactName").current.value;
        result.email = getRef(refArrFieldRefs.current, "contactEmail").current.value;
        result.phone = getRef(refArrFieldRefs.current, "contactPhone").current.value;
        result.message = getRef(refArrFieldRefs.current, "contactMsg").current.value;
    
        return result;
    }

    // EVENTS
    const handleSubmit = async (e) => {

        e.preventDefault();

         // Try and send this message
         // This is the body of our request sent to the back end
         const body = createBodyObject();
 
         // await for the server response from back end
         const result = await sendMessage(body);
 
         if(result.messageSent){
            
            // Due to the message changing at the top we force the height of this to remain the same
            refFormContainerHeight.current = refFormContainerHeight.current - refBlurb.current.clientHeight;

            // Add the animation classes
            refFormContainer.current.classList.add("letter-animation");
            refButtonSubmit.current.classList.add("hidden")
            refEnvHiddenBG.current.classList.add("show-hidden-background-animation");
            refEnvelope.current.classList.add("envelope-show-animation");
            refEnvLid.current.classList.add("open-close-animation");

            // Scroll to the top so user can see message (could be using small device)
            window.scrollTo(0, 0);
 
         }else {
             toastError(`Cannot send message`,result.status, result.response);
         }
    }
    
    const handleField_Change = (e) => {
    
        // This is our target field being changed
        const target = e.currentTarget

        // This is our targets value with line breaks removed as we shouldn't count these
        const myValue = target.value.replace(/(\r\n|\n|\r)/gm, "");

        // Make sure it passes validation as well
        const validationPassed = validateValue(target.type, myValue)

        // make a copy of our array of objects held by the state
        let arrCopy = [...state.arrFields];

        // find our object from array that corresponds to the target field that just changed
        // (variable is a pointer to the object in the copied array - not a copy of the object)
        let o = arrCopy.find(o => o.id === target.id)

        // if required length in field has been met then we should show the tick image
        let showTick = (myValue.length >= Number(o.minLength) 
                        && myValue.length !== 0
                        && validationPassed)

        // If we have altered the showTick flag then update the state
        if (showTick !== o.blnShowTick) {
            // updated object with new flag
            o.blnShowTick = showTick

            // Do we enable the submit button (every required field must be completed correctly)
            const arrRequired = arrCopy.filter(o => o.blnRequired === true);
            const blnEnableSubmitButton = (arrRequired.filter(o => o.blnShowTick === false).length === 0)

            // Update the state so it can re-render
            setstate({...state, 
                blnEnableSubmitButton,
                arrFields: arrCopy
              });
        }
    }

    // Load: Simulates componentDidMount() method.
    // This will only run once - when the component has mounted
    useEffect(() => {
        // This element is from a higher order so I cannot override it using CSS as it will 
        // make the change across all the components (since CSS is global and overwrites properties for all components / pages)
        document.getElementById("body-container").style.backgroundColor = state.bgColour;

        // Remember the height of this element as we want to keep it this way after the animation
        // (I've deducted 100 pixels because the message at the top has a break tag which moves everything down
        // and the animation makes the container height change. This deduction puts the height back to normal)
        refFormContainerHeight.current = refFormContainer.current.clientHeight;

        // Scroll to the top
        window.scrollTo(0, 0);

        // Set the focus to the first input field
        refArrFieldRefs.current[0].current.focus();

        // Unload: Simulates componentWillUnmount() method.
        // Cleanup abilities when this component is removed from the DOM
        return () => {
            const BodyContainerStyle = document.getElementById("body-container").style;

            if(BodyContainerStyle.backgroundColor)
                BodyContainerStyle.removeProperty("background-color");
        }
        
    },[])

    return (

        <div className="form-card">
            <h2 className="center-font">Contact Us</h2>

            <p ref={refBlurb} className="center-font"
                dangerouslySetInnerHTML={getBlurb(state.blnAnimationFinished)}
                >
            </p>

            <form
                ref={refFormContainer} 
                className="form-container" 
                onSubmit={handleSubmit}
                style={{height: refFormContainerHeight.current ? 
                        String(refFormContainerHeight.current) + "px" : 
                        null}}
                >
                {state.blnAnimationFinished ? null :
                    <Fields
                        refArrFieldRefs = {refArrFieldRefs}
                        arrFields = {state.arrFields}
                        onChange = {handleField_Change}
                    />
                    }
                    <button 
                        ref={refButtonSubmit}
                        className={`standard ${!state.blnEnableSubmitButton ? "disabled" : ""}`}  
                        disabled={!state.blnEnableSubmitButton} 
                        type="submit"
                        tabIndex="5">
                        Submit
                    </button>
            </form>

            <div ref={refEnvHiddenBG} className="contact-hidden-background"></div>
            <div ref={refEnvelope} className="contact-envelope"></div>
            <div ref={refEnvLid} 
                className="contact-envelope-lid"
                onAnimationEnd={envelopeLid_onAnimationEnd}></div>
            
        </div>

    )

}

export default Contact