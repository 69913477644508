import { localStorageName } from "./config.json";

export function showCookieAlert() {
  // If this key is not in local storage we need to set it (return true)
  return localStorage.getItem(localStorageName) === null;
}

export function setCookieAlert() {
  localStorage.setItem(localStorageName, true);
}
