import React, { useState } from "react";

const ScrollToTop = () => {
  // setBlnScroll method updates the blnScroll state
  const [blnScroll, setBlnScroll] = useState(false);

  // Method runs when window scroll event occurs
  const handleOnScroll = () => {
    if (!blnScroll && window.pageYOffset > 150) {
      setBlnScroll(true);
    } else if (blnScroll && window.pageYOffset <= 150) {
      setBlnScroll(false);
    }
  };

  // Add an event listener to the window (scroll event triggers handleOnScroll)
  window.addEventListener("scroll", handleOnScroll);

  // Method makes the window scroll back up to the top
  const handleOnClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // return the "scroll to top" button
  return (
    <button
      id="btnScrollToTop"
      onClick={handleOnClick}
      title="Scroll To Top"
      style={{ display: blnScroll ? "block" : "none" }}
    >
      Top
    </button>
  );
};

export default ScrollToTop;
