// Node Modules
import React, {useState, useEffect, useRef} from "react";

// Components
import Fields from "./common/fields";
import VisitorInfo from "../components/admin/visitorinfo";
import ResetPassword from "../components/admin/resetpwd";
import UserAccounts from "./admin/userAccounts";

// Custom Modules
import auth from "../services/authService";
import {getMyUserDetails} from "../services/userService";
import {toastError} from "../js/toast/toast"


function Admin() {

    // STATE
    const [state, setstate] = useState({
        view: null,
        arrFields: [
            {
                id: "cmbSelect",
                fieldType: "Select",
                noAnimation: true,
                tabIndex: "1",
                blnFullWidth: true,
                blnShowTick: false,
                txtLabel: "View:",
                blnRequired: true,
                arrSelectOptions : ["Visitor Data","Reset Password"],
            }
        ]
    });

    // REFS
    // A list of refs for each rendered element for dynamically created fields
    // (fields created are based on the arrFields array shown above)
    // When the item is rendered a reference to the field is created via <Fields/>
    const refArrFieldRefs = useRef([]);
    const refUser = useRef(auth.getCurrentUser());

    // GENERIC FUNCTIONS
    const renderSelectedView = () => {
        switch (state.view) {
            case "Visitor Info": return <VisitorInfo/>
            case "Reset Password": return <ResetPassword/>
            case "User Accounts": return <UserAccounts currentUser={refUser.current}/>

            default: return <VisitorInfo/>;
        }
    }

    // EVENTS
    const handleSelect_Change = (e) => {
        // This is our target field being changed
        const target = e.currentTarget

        // Render on the screen what the user selected
        setstate({...state, view: target.value})
    }

    // When component loads...
    useEffect(() => {

        async function fetchData() {

            // Obtain the latest user details from the database (we need to know if they are an admin)
            const res = await getMyUserDetails()

            if(!res.successful){
                return toastError(`Cannot obtain admin status for current user`,res.status,res.response)
            }

            // if we are an admin then we can access user accounts info
            if(res.response.is_admin === 1) {

                // make a copy of our array of fields held by the state
                let arrCopy = [...state.arrFields];

                // find our select field we want to update (pointer to object)
                const selectField = arrCopy.find(o => o.id === "cmbSelect");

                // Add this option to our select list
                selectField.arrSelectOptions.push("User Accounts")

                // Rerender this component using the latest values / settings
                setstate({...state,arrFields: arrCopy})
            }
            
        }

        // Scroll to the top
        window.scrollTo(0, 0);

        fetchData();
    },[])

    return (
        <>
            <div className="middle-content">
                <h2 className="center-font">Admin</h2>
                <Fields
                    refArrFieldRefs = {refArrFieldRefs}
                    arrFields = {state.arrFields}
                    onChange = {handleSelect_Change}
                />
                {renderSelectedView()}
            </div>
        </>
    )
}

export default Admin