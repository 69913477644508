//  Node Modules
import React, {useState} from "react";

// Components
import SkillsCard from "./skillsCard";

const SectionSkills = (props) => { 

  // PROPS
  const {
    arrItemsCategorised,
    arrSkill_ids_enabled} = props;

  // STATE
  const [state, setstate] = useState({
    skill_id_selected: 0
  });

  
  // EVENTS
  const handle_onClick = (e) => {

    const target = e.currentTarget

    // Ignore disabled items
    if(target.classList.contains("disabled")) {
      return;
    }

    // Based on the div ID we just clicked on we can update the 
    // skill we selected so it displays an info bubble below it.
    const this_skill_id = Number(target.id);

    setstate({...state, 
      skill_id_selected: (state.skill_id_selected===this_skill_id ? 0 : this_skill_id)})

  }

  return (
    <>
        <div className="about-skills-section">

        {arrItemsCategorised
              ? arrItemsCategorised.map(arr => (
                <SkillsCard
                key={arr[0].category}
                category={arr[0].category}
                arrItems={arr}
                skill_id_selected={state.skill_id_selected}
                arrSkill_ids_enabled={arrSkill_ids_enabled}
                handle_onClick={handle_onClick}
              />
          ))
          : null}

        </div>
    </>
  );

};

export default SectionSkills;