// Node Modules
import React, {useState, useEffect, useContext} from "react";
import { withRouter } from "react-router-dom";

// Public Variables / Data
import {ContextPublic} from "../store"

// Custom Modules
import {showCookieAlert, setCookieAlert} from "../js/cookieAlert/cookieAlert"

// Components
import Blurb from "./home/blurb";
import FlexCards from "./home/flexCards";
import Flexlogos from "./home/flexLogos";
import Stripe from "./common/stripe";
import CookieAlert from "./common/cookieAlert"

// Images
import imgDesign from "../images/standard/Logo Design.svg"
import imgDev from "../images/standard/Logo App Dev.svg"
import imgDatabase from "../images/standard/Logo Database.svg"
import imgAnalytics from "../images/standard/Logo Analytics.svg"

const Home = () => {

  // STATE
  const [state, setstate] = useState({
                                    blnShowCookieAlert: false
                                    });

  // This is an array of card objects to be displayed on the home page
  const arrCards = [
    {
      id: "cardDesign",
      url: "/work",
      img: imgDesign,
      imgAlt: "Design Logo",
      header: "Design",
      text:
        "Eye catching, pixel perfect images and layouts that make your apps and websites stand out from others",
    },

    {
      id: "cardDev",
      url: "/work",
      img: imgDev,
      imgAlt: "App Dev Logo",
      header: "App Development",
      text:
        "Make tasks easy with fully customised user-friendly applications that look good and improve workflow",
    },

    {
      id: "cardDatabase",
      url: "/work",
      img: imgDatabase,
      imgAlt: "Database Logo",
      header: "Databases",
      text:
        "All your data ends up here. It's important it is secure and runs super fast so there are no delays to getting stuff done",
    },

    {
      id: "cardAnalytics",
      url: "/work",
      img: imgAnalytics,
      imgAlt: "Analytics Logo",
      header: "Analytics",
      text:
        "Gain valuable insights from mathematical models, dashboards and reports so you can make proactive decisions using facts and forecasts",
    },
  ];

  // GENERIC FUNCTIONS
  const handle_CookieAlertClose = () => {
    setCookieAlert();
    setstate({...state, blnShowCookieAlert: false})
  }

  // EVENTS
  // When component loads...
  useEffect(() => {
    // Scroll to the top
    window.scrollTo(0, 0);

    // Do we show cookie alert
    setstate({...state, blnShowCookieAlert: showCookieAlert()})
 
  }, [])

  // Obtain our array of logos from the public context
  const [statePublic] = useContext(ContextPublic)

  // A context consumer expects a function - not HTML
  // so to return values from our context we use lambda expressions
  return (
        <>
          {state.blnShowCookieAlert ? <CookieAlert btnClose_onClick={handle_CookieAlertClose}/>: null}
          <img className="banner" alt="Banner" />
          <div className="middle-content">
            <Blurb />
            <FlexCards arrCards={arrCards} />
          </div>
          <Stripe
            url="contact"
            imgAlt="Idea"
            text="Got an idea and want to turn it into a reality?"
            buttonText="Get In Touch"
          />

          <Flexlogos arrLogos={statePublic.arrLogos} />
        </>
      )
}

export default withRouter(Home);
