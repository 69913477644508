// Node Modules
import React, { Component } from "react";

// Components
import Logo from "./logo";

// Home > Flexlogos > logo
// (props are passed from parent)

class Flexlogos extends Component {
  render() {
    const { arrLogos } = this.props;

    return (
      <>
        <p className="standard-margin">
          Here are some of the organisations we have worked with...
        </p>
        <div className="flex-logos-home standard-margin">
          {arrLogos
            ? arrLogos.map((logo) => (
                <Logo key={logo.logo_id} img={logo.img} imgAlt={logo.imgAlt} />
              ))
            : null}
        </div>
      </>
    );
  }
}

export default Flexlogos;
