import React from "react";
import { Link } from "react-router-dom";

//  Images
import imgCookies from "../../images/standard/cookies.svg"

const CookieAlert = (props) => {

  const {
    btnClose_onClick,
    } = props;
  
  return (
    <div id="myModal" className="modal">
    <div className="modal-content">
      <div className="top-bar">
        <div id="btnClose" onClick={btnClose_onClick}>
          &times;
        </div>
      </div>
      
      <div className="modal-body center-font ">
          <h3>Cookies!<br/> Om nom nom...</h3>
          <div><img className="medium" src={imgCookies} alt="cookies" /></div>
          <p>We use cookies on this site to enhance your user experience.<br/>Please see our <Link to="/privacy">privacy policy</Link> for more information</p>
          <div>
            <button 
                className="standard"
                disabled={false}
                type="button"
                onClick={btnClose_onClick}
                tabIndex="1">
                Got it
            </button>
          </div><br/>
      </div>
      
    </div>
  </div>
  );
};

export default CookieAlert;
