// === ABOUT ===
// Contexts allow other child elements to access values without prop drilling (passing values from one to the other in a hierarchy)
// They are essentially public variables / properties that can be used by elements inside them (bypassing entire chains of elements)

// ContextPublic         (Holds an object with properties that are shared by at least 2 components - e.g. home and work)

// Node Modules
import React, {useState, useEffect} from 'react';

// Services (Obtain Data etc)
import svcAPI from "./services/apiService";
import svcLogos from "./services/logosService";


// Declare new contexts that can be used by child elements
// (values entered into these contexts can be read and set by any element inside them)
export const ContextPublic = React.createContext('ContextPublic');


// Give the contexts names so you can see them in the browser
// (This allows our browser to show the correct context name for tree hierarchy)
ContextPublic.displayName = "ContextPublic";

// Store is our element name for holding all the contexts (public variables \ properties)
const Store = ({children}) => {

  // STATE
  const [statePublic, setstatePublic] = useState({
                                      apiConnection: false,
                                      arrLogos: []
                                      });

  //  EVENTS
  // (This will only run once - when the component has mounted)
  useEffect(() => {
    async function getAPIData () {

        setstatePublic({
          apiConnection: await svcAPI.getData(), 
          arrLogos: await svcLogos.getData()
        })

    }

   getAPIData()

  },[])


  // We wrap the context providers around the {children} passed in so child elements 
  // (in this case the App element and sub-selements) have access to the parent contexts
  return (
    <ContextPublic.Provider value={[statePublic, setstatePublic]}>
        {children}
    </ContextPublic.Provider>
  );
};

export default Store;