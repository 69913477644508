import React, {forwardRef} from "react";

// We have to use forwardRef because we want to set focus to the first
// input field once everything has been rendered. This occurs in the ultimate parent element
const Select = forwardRef((props,ref) => {
  
  const { 
      id
    , noAnimation
    , tabIndex
    , blnFullWidth
    , blnShowTick
    , txtLabel
    , blnRequired
    , imgFieldIndicator
    , onChange
    , arrSelectOptions} = props;

  return (
    <div className={`field-container 
                    ${blnFullWidth ? "fullwidth" : "halfwidth"} 
                    ${noAnimation ? "no-animation" : ""}`}>
        <span className="field-label">{txtLabel}&nbsp;</span>
        {
          blnShowTick ? 
          <img src={imgFieldIndicator} className="field-indicator-image" alt="Completed Tick" /> :
          <span className="field-indicator">{blnRequired ? "Required" : "Optional"} </span>
        }
        
        <select
          id={id} 
          ref={ref}
          className="field-select" 
          tabIndex={tabIndex} 
          required={blnRequired ? "required" : ""} 
          onChange={onChange}>
              {arrSelectOptions ? arrSelectOptions.map((o,i) => <option key={i} value={`${o}`}>{`${o}`}</option>) : null}
        </select>
    </div>
  );
  
}
);

export default Select;