// Custom modules
import http from "./httpService";
import { apiProfilesURL } from "./config.json";

export async function getData() {
  // This is an array of profiles for each person on the about page
  const results = await http.get(apiProfilesURL);
  return results.data;
}

export default {
  getData,
};
